import { createApp } from 'vue'

import App from './App.vue'
import router from './router/index'
import store from "./store/store"

import API from "./services/api"

import VCalendar from 'v-calendar';
import AddDataModal from "./assets/AddDataModal.vue"
import VueChartkick from 'vue-chartkick'
import Datepicker from '@vuepic/vue-datepicker';
import VueTheMask  from 'vue-the-mask'
//import alertPlugin from '@/plugins/toast'
import { VTooltip, VClosePopper } from 'floating-vue'

import '@/styles/input.css'
import '@vuepic/vue-datepicker/dist/main.css'
import 'v-calendar/dist/style.css';
import "chartkick/chart.js"

import { Form } from "vee-validate";

// import VueTabs from 'vue-nav-tabs'
// import 'vue-nav-tabs/themes/vue-tabs.css'
// import Chart from 'chart.js'
// import Chartkick from "chartkick"
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

const vuetify = createVuetify({
  components,
  directives,
})

const app = createApp(App)

app.config.globalProperties.$api = API
app.provide('axios', app.config.globalProperties.$api)

app.component('DatePicker', Datepicker)
app.component('AddDataModal', AddDataModal)
app.component("FormComponent", Form)

app.directive('tooltip', VTooltip)
app.directive('close-popper', VClosePopper)

app.use(VueChartkick)
app.use(VueTheMask)
// app.use(VueTabs)

app.use(VCalendar, {})
app.use(vuetify)
//app.use(alertPlugin, {})

app.use(store)
app.use(router)
	.mount('#app')

