<template>
  <div class="body bg-[#F8F8F8] shadow-inner">
    <NavComponent></NavComponent>

    <router-view></router-view>
    <!--
    <div class="w-full static bottom-0">

    </div>-->

    <!-- <FooterComponent v-if="user"></FooterComponent> -->
  </div>
</template>

<script>
import NavComponent from "./components/NavComponent.vue";
// import FooterComponent from "./components/FooterComponent.vue";

import { useStore } from "vuex";
import { computed, watch } from "@vue/runtime-core";
import base from "./services/api";

export default {
  name: "App",

  components: {
    NavComponent,
    // FooterComponent
  },

  setup() {
    const store = useStore();
    const user = computed(() => store.getters["auth/getUser"]);

    watch(
      user,
      (v) => {
        if (v) {
          store.dispatch("fetchProfiles", v.token);
          base.defaults.headers.common[
            "Authorization"
          ] = `ASSIC ${user.value.token}`;
        }
      },
      { immediate: true }
    );
  },
};
</script>

<style>
@font-face {
  font-family: "PoppinsLight";
  src: url(./fonts/PoppinsLight.otf) format("truetype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: url(./fonts/PoppinsRegular.otf) format("truetype");
}

@font-face {
  font-family: "HelveticaNeueBold";
  src: url(./fonts/HelveticaNeueBold.ttf) format("truetype");
}

@font-face {
  font-family: "HelveticaNeueMedium";
  src: url(./fonts/HelveticaNeueMedium.ttf) format("truetype");
}

@font-face {
  font-family: "HelveticaNeueRegular";
  src: url(./fonts/HelveticaNeueRegular.ttf) format("truetype");
}

body {
  font-family: "PoppinsLight", Arial;
  color: #303030;
}

.headingOne {
  font-family: "HelveticaNeueMedium", Arial;
  color: #161922;
}

.headingTwo {
  font-family: "HelveticaNeueRegular", Arial;
  color: #161922;
}

.headingSections {
  font-family: "HelveticaNeueMedium", Arial;
  color: #161922;
}

/* @font-face {
  font-family: "Helvetica";
  src: local("Helvetica"),
    url(../src/fonts/HelveticaNeue-Light.ttf) format("truetype");
}

.body {
  font-family: "Helvetica", Arial;
  font-size: 20px;
} */
</style>
