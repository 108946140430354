<template>
	<div class="grid grid-cols-3 mt-5 gap-5">
		<select v-model="selectedOption" class="modalSelect focus:ring-0" name="options">
        <option disabled selected>Select</option>
        <option v-for="(fo, i) in myOptions" :key="i" :value="fo.id">{{ fo.name }}</option>
      </select>
		<div v-if="myForm" class="col-span-2 modalForm">
      <label class="label -mb-1">
        <span class="inputLabel">Pick a Date</span>
      </label>
      <Datepicker v-model="selectedDate" autoApply></Datepicker>
			<template v-for="(fi, j) in myForm" :key="j">
				<div v-if="fi.value_type == 'integer'" class="form-control w-full overflow-y-auto">
					<label :for="fi.name" class="label -mb-1">
						<span class="inputLabel">{{ fi.ui_name }} ({{ fi.unit }})</span>
					</label>
					<input v-model.number="submitForm[fi.name]" type="number" :name="fi.name" placeholder="Type here"
						class="textInput" />
				</div>
				<div v-else class="form-control w-full">
					<label :for="fi.name" class="label -mb-1">
						<span class="inputLabel">{{ fi.ui_name }} ({{ fi.unit }})</span>
					</label>
					<input v-model.trim="submitForm[fi.name]" type="text" :name="fi.name" placeholder="Type here"
						class="textInput" />
				</div>

			</template>
			<div class="flex justify-between">
				<div></div>
				<button class="primaryBtn w-40 mt-5" @click="addData()">Add Data</button>
			</div>

		</div>
	</div>
</template>

<script>
import base from "../../services/api";
import { useStore } from "vuex";
import { computed, ref, watch } from "vue"
import { map, find } from "lodash";
import Datepicker from "@vuepic/vue-datepicker";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  components: {
    Datepicker,
  },

  setup() {
    const selectedDate = ref();

		const store = useStore();
		const user = computed(() => store.getters["auth/getUser"]);

		const myProfile = computed(() => store.getters['painProfile'])
		const myOptions = computed(() => map(myProfile.value?.dataset_set, ({ name, id }) => ({ name, id }))) ?? []
		const selectedOption = ref(null)

		const submitForm = ref({})


		const myForm = computed(() => find(myProfile.value?.dataset_set, { id: selectedOption.value * 1 })?.structure?.elements)

		watch(selectedOption, () => {
			submitForm.value = {}
		})


		function addData() {

			const postAPI = find(myProfile.value?.dataset_set, { id: selectedOption.value * 1 })?.post_api_name;

			const apiName = "/research/";

			base
				.post(
					`${apiName}${postAPI}/`,
					{ ...submitForm.value, created_on: selectedDate.value, },
					{
						headers: {
							Authorization: `ASSIC ${user.value.token}`,
						},
					}
				)
				.then((response) => {
					if (response.data?.status === "success") {
            toast('Pain Score Data Sucessfully Added!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
					}
				})
				.catch((e) => {
					console.error("Add Pain Score Data Error", e);
          toast('Unable To Add Pain Score Data!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
				});
		}

		return {
			selectedOption,
			myForm,
			myProfile,
			myOptions,
			addData,
			submitForm,
      selectedDate
		}
	},
}
</script>

<style>
</style>
