<template>
  <HeaderShape></HeaderShape>

  <section class="relative pt-5 pb-12 bg-[#F8F8F8]">
    <div class="container mx-auto lg:px-5 xl:px-20">
      <!-- HEADER -->
      <div class="headerDiv mb-5">
        <base-card class="p-0">
          <img src="../../public/images/pf.png" alt class="rounded w-32 h-32 mx-auto" />
        </base-card>
        <base-card class="col-span-5 flex bg-white">
          <p class="headingOne self-center text-paragraph-500 font-normal">
            My
            <span class="font-bold">Performance Fingerprint</span> Profile
          </p>
        </base-card>
      </div>

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 bg-white p-10 rounded mb-6 shadow-xl">
        <ProfileSectionCard
          v-for="profileSection in profileSections"
          :key="profileSection.title"
          :profileSection="profileSection"
        ></ProfileSectionCard>
      </div>
    </div>
  </section>

  <FooterComponent></FooterComponent>
</template>

<script>
import ProfileSectionCard from "../components/ProfileSectionCard.vue";
import BaseCard from "../assets/BaseCard.vue";
import HeaderShape from "../assets/HeaderShape.vue";
import FooterComponent from "../components/FooterComponent.vue";

export default {
  name: "DashboardView",

  components: {
    ProfileSectionCard,
    BaseCard,
    HeaderShape,
    FooterComponent,
  },

  data() {
    return {
      selectedTab: "profiles",

      profileSections: [
        {
          title: "Activity",
          desc: "Activity profile allow daily input data on training time, match time, gymnasium time and step counter as well as custom user defined activity items.",
          btnText: "View Activity Profile",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/activity.png",
          to: "/activityprofile",
        },
        {
          title: "Fitness",
          desc: "Exercise fitness testing is a measure of the body’s adaptation to a given or increasing workload. Monitor your fitness.",
          btnText: "View Fitness Profile",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/fitness.png",
          to: "/fitnessprofile",
        },
        {
          title: "Body",
          desc: "Body allow the user to track their mass, (BMI) and skin fold thickness measurements together with their expected daily caloric requirements.",
          btnText: "View Body Profile",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/body.png",
          to: "/bodyprofile",
        },
        {
          title: "Pain Score",
          desc: "View and track your pain level before, during and after exercise.",
          btnText: "View Pain Score Profile",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/research.png",
          to: "/painprofile",
        },
        {
          title: "Conditioning Tests",
          desc: "Monitor speed, strength & endurance conditioning parameters.",
          btnText: "View Conditioning Tests Profile",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/strength.png",
          to: "/conditioningprofile",
        },
        {
          title: "Medical",
          desc: "View your medical history on a secure platform and add other medical information.",
          btnText: "View Medical Profile",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/medical_history.png",
          to: "/medicalprofile",
        },

        {
          title: "Laboratory Tests",
          desc: "Any investigation e.g. laboratory test can be entered and tracked.",
          btnText: "View Laboratory Tests Profile",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/labtest.png",
          to: "/labprofile",
        },
        {
          title: "Vitals",
          desc: "Add and view different Vital parameters to help measure and track and your health.",
          btnText: "View Vitals Profile",
          src: "https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/vitals.png",
          to: "/vitalsprofile",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>
