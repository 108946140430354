<template>
	<!-- FOOTER -->
	<div class="bg-primary-500 p-5 py-10 shadow-lg text-white">
		<div class="w-4/5 mx-auto grid grid-cols-4">
			<div class="col-span-2">
				<h3 class="headingSections text-left text-base mb-2 text-white">Assic Apps</h3>
				<ul class="list-disc ml-3 footerList">
					<li class="text-xs my-2">
						<a target="_blank" href="https://www.assic-health.com/assic-apps/fitness-app/">Fitness App</a>
					</li>
					<li class="text-xs my-2">
						<a target="_blank" href="https://www.assic-health.com/assic-apps/performance-fingerprint/">Performance
							Fingerprint</a>
					</li>
					<li class="text-xs my-2">
						<a target="_blank" href="https://www.assic-health.com/assic-apps/recovery-heart-rate/">Recovery Heart
							Rate</a>
					</li>
					<li class="text-xs my-2">
						<a target="_blank" href="https://www.assic-health.com/assic-apps/resting-heart-rate/">Resting Heart
							Rate</a>
					</li>
					<li class="text-xs my-2">
						<a target="_blank" href="https://www.assic-health.com/assic-apps/strength-and-conditioning/">Strength &
							Conditioning</a>
					</li>
				</ul>
			</div>
			<div>
				<h3 class="headingSections text-left text-base mb-2 text-white">Terms of Service</h3>
				<ul class="list-disc ml-3 footerList">
					<li class="text-xs my-2">
						<a target="_blank" href="https://www.assic-health.com/privacy-policy/">Privacy Policy</a>
					</li>
					<li class="text-xs my-2">
						<a target="_blank" href="https://www.assic-health.com/terms-and-conditions/">Terms &
							Conditions</a>
					</li>
				</ul>
			</div>
			<div>
				<h3 class="headingSections text-left text-base mb-2 text-white">Contact</h3>
				<ul class="list-disc ml-3 footerList">
					<li class="text-xs my-2"><a target="_blank" href="tel:+27(0)824959141">+27(0) 82 495 9141</a></li>
					<li class="text-xs my-2"><a target="_blank" href="tel:+27(0)218794264">+27(0) 21 879 4264</a></li>
					<li class="text-xs my-2"><a target="_blank" href="mailto:info@assic-health.com">info@assic-health.com</a></li>
					<li class="text-xs my-2">
						<a
							href="https://www.google.com/maps/search/7th+Floor,+New+Era+Hospital,+MSC+House,+1+Mediterranean+Street,+Cape+Town+Foreshore,+8000/@-33.9193774,18.4303414,17z/data=!3m1!4b1">7th
							Floor, New Era Hospital, MSC House, 1 Mediterranean
							Street, Cape Town Foreshore, 8000</a>
					</li>
				</ul>
			</div>
		</div>
		<div class="border-t mt-5"></div>
		<div class="w-4/5 mx-auto grid grid-cols-4 pt-5 ">
			<div class="col-span-3">
				<p class="text-xs my-2">{{ currentYear }} © <a href="https://www.assic-health.com/" class="font-bold">ASSIC
						Medical
						(PTY) Ltd </a>| All Rights
					Reserved</p>
				<p class="text-xs my-2">Developed and hosted by <a href="https://www.lavalamp.biz/"
						class="font-bold">Lava
						Lamp Lab</a></p>
			</div>
			<div class="flex self-center">
				<a target="_blank" href="https://www.facebook.com/assichealth/"><img class="w-4 h-4 mx-2" src="../assets/img/FaceBook.svg" alt=""></a>
				<a target="_blank" href="https://www.youtube.com/channel/UCyysERn0Xep58k0N_1OU67Q"><img class="w-4 h-4 mx-2" src="../assets/img/StartButton.svg" alt=""></a>
				<a target="_blank" href="https://www.linkedin.com/company/assichealth/"><img class="w-4 h-4 mx-2" src="../assets/img/LinkedIn.svg" alt=""></a>
				<a target="_blank" href="https://www.instagram.com/assichealth/"><img class="w-4 h-4 mx-2" src="../assets/img/Instagram.svg" alt=""></a>
			</div>
		</div>
	</div>
</template>

<script>
  export default {
    name: "FooterComponent",
    setup() {

      const currentYear = new Date().getFullYear();

      return {
        currentYear
      }
    },
  }
</script>

<style lang="postcss" scoped>
</style>
