<template>
  <!-- Dates -->

  <div class="grid grid-cols-2 gap-5 mb-8 font-paragraph-500">
    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-4 gap-2">
        <h1 class="headingOne text-base self-center font-normal col-span-2">
          Select Date Range
        </h1>
        <button
          class="secondaryBtn text-xs focus:bg-[#035795] focus:border-[#035795] active:bg-[#035795]"
          @click="getCurrentMonth()"
        >
          This Month
        </button>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="getCurrentYear()"
        >
          This Year
        </button>
      </div>
    </div>

    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-3 gap-2">
        <base-date-time-picker
          v-model="fromDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <base-date-time-picker
          v-model="toDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="customizeDateRange()"
        >
        Customize Date Range
        </button>
      </div>
    </div>
  </div>

  <!-- Tabs -->
  <div class="grid grid-cols-4 gap-2 mb-4">
    <button class="btnTab" @click="getAllergy(); determineCurrentTab('Allergy');">
      Allergy
      Data
    </button>
    <button class="btnTab" @click="getMedicalEvent(); determineCurrentTab('MedicalEvent')">
      Medical Event
      Data
    </button>
    <button class="btnTab" @click="getSurgeryEvent(); determineCurrentTab('SurgeryEvent')">
      Surgery Event
      Data
    </button>
    <button class="btnTab" @click="getFamilyEvent(); determineCurrentTab('FamilyEvent')">
      Family Medical Event
      Data
    </button>
    <button class="btnTab" @click="getExerciseEvent(); determineCurrentTab('ExerciseEvent')">
      Exercise Event
      Data
    </button>
    <button class="btnTab" @click="getMedication(); determineCurrentTab('Medication')">
      Medication
      Data
    </button>
    <button class="btnTab" @click="getGeneralEvent(); determineCurrentTab('GenrealEvent')">
      General Event
      Data
    </button>
  </div>

  <!-- Chart -->
  <div class="relative bg-gray-300 rounded-lg rounded-t-none p-10 mb-5">
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, nextTick, defineEmits, defineProps } from "vue";
  import base from "../../services/api";
  import { formatISO } from "date-fns";
  import moment from "moment";
  import { Chart, registerables } from "chart.js";

  import BaseDateTimePicker from "../../../src/assets/Form/BaseDateTimePicker.vue";

  Chart.register(...registerables);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm"); // Consistent date formatting
  };

  const emit = defineEmits(["retrieveTabId"]);
  const emitDataID = async (id) => {
    emit("retrieveTabId", id);
  };

  const props = defineProps({
    securityCode: Number
  });

  const fromDate = ref("");
  const toDate = ref("");

  const graphData = {};
  const dataList = ref([]);
  const categoriesList = ref(null);
  const dataSeriesList = ref(null);
  const dataSeriesList2 = ref(null);
  const dataSeriesList3 = ref(null);
  const dataSeriesList4 = ref(null);
  const dataSeriesList5 = ref(null);
  const dataSeriesList6 = ref(null);

  const datasets = ref(null);

  const ctx = ref(null);

  const allergyTab = ref(true);
  const medicalEventTab = ref(false);
  const surgeryEventTab = ref(false);
  const familyEventTab = ref(false);
  const exerciseEventTab = ref(false);
  const medicationTab = ref(false);
  const genrealEventTab = ref(false);

  const chartConfig = computed(() => { return {
    type: "line", // Define the type of chart
    data: {
      labels: categoriesList.value, // Use dynamic list of labels (dates)
      datasets: datasets.value, // Dynamic datasets
    },
    options: {
      maintainAspectRatio: true,
      responsive: true,
      scales: {
        x: [{
          type: 'time',
          time: {
            unit: 'second',
            displayFormats: {
              second: 'YYYY-MM-DD HH:mm'
            }
          },
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        }],
        y: {
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        },
      },
      title: {
        display: false,
      },
      legend: {
        labels: {
          fontColor: "white",
        },
        align: "end",
        position: "bottom",
      },
      tooltips: {
        mode: "index", // Tooltip mode
        intersect: false,
      },
      hover: {
        mode: "nearest", // Hover behavior
        intersect: true,
      },
    },
  }});

  let myLine = null;

  const generateGraph = async (ctx, chartConfig) => {
    myLine = new Chart(ctx, chartConfig);
  }

  const getCurrentDate = async () => {
    const currentDate = new Date();
    toDate.value = formatISO(currentDate);
  }

  const getCurrentYear = async () => {
    const firstDayYear = new Date(new Date().getFullYear(), 0, 1);
      fromDate.value = formatISO(firstDayYear);
      refreshTabs();
  };

  const customizeDateRange = async () => {
      refreshTabs();
  };

  const getCurrentMonth = async () => {
    const firstDayMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );

      fromDate.value = formatISO(firstDayMonth);
      refreshTabs();
  };

  const generateDataSet = async (label,
      data,
      backgroundColor = "#F04248",
      borderColor = "#F04248") => {
        graphData.value = {
            label: label,
            backgroundColor: backgroundColor,
            borderColor: borderColor,
            data: data,
            fill: false
          }
  };

  const updateChart = async () => {
      myLine.data.labels = categoriesList.value;
      myLine.data.datasets = datasets.value;
      myLine.update();
  };

  const determineCurrentTab = async (tabType) => {

  /*
    This function is reponsible ensuring that the user does not get taken to a tab they do not wish to view
    when adding data to customizing dates
  */
    if(tabType == "Allergy"){
      allergyTab.value = true;
      medicalEventTab.value = false;
      surgeryEventTab.value = false;
      familyEventTab.value = false;
      exerciseEventTab.value = false;
      medicationTab.value = false;
      genrealEventTab.value = false;
    }
    if(tabType == "MedicalEvent"){
      allergyTab.value = false;
      medicalEventTab.value = true;
      surgeryEventTab.value = false;
      familyEventTab.value = false;
      exerciseEventTab.value = false;
      medicationTab.value = false;
      genrealEventTab.value = false;
    }
    if(tabType == "SurgeryEvent"){
      allergyTab.value = false;
      medicalEventTab.value = false;
      surgeryEventTab.value = true;
      familyEventTab.value = false;
      exerciseEventTab.value = false;
      medicationTab.value = false;
      genrealEventTab.value = false;
    }
    if(tabType == "FamilyEvent"){
      allergyTab.value = false;
      medicalEventTab.value = false;
      surgeryEventTab.value = false;
      familyEventTab.value = true;
      exerciseEventTab.value = false;
      medicationTab.value = false;
      genrealEventTab.value = false;
    }
    if(tabType == "ExerciseEvent"){
      allergyTab.value = false;
      medicalEventTab.value = false;
      surgeryEventTab.value = false;
      familyEventTab.value = false;
      exerciseEventTab.value = true;
      medicationTab.value = false;
      genrealEventTab.value = false;
    }
    if(tabType == "Medication"){
      allergyTab.value = false;
      medicalEventTab.value = false;
      surgeryEventTab.value = false;
      familyEventTab.value = false;
      exerciseEventTab.value = false;
      medicationTab.value = true;
      genrealEventTab.value = false;
    }
    if(tabType == "GenrealEvent"){
      allergyTab.value = false;
      medicalEventTab.value = false;
      surgeryEventTab.value = false;
      familyEventTab.value = false;
      exerciseEventTab.value = false;
      medicationTab.value = false;
      genrealEventTab.value = true;
    }
  }

  const refreshTabs = async () => {

    /*
      This function is reponsible for refreshing graphs when a user adds data or selects date ranges
      This is to ensure that data can instantly retrieved without the user needing to refresh the page everytime
    */

    if(allergyTab.value == true){
      getAllergy();
    }
    if(medicalEventTab.value == true){
      getMedicalEvent();
    }
    if(surgeryEventTab.value == true){
      getSurgeryEvent();
    }
    if(familyEventTab.value == true){
      getFamilyEvent();
    }
    if(exerciseEventTab.value == true){
      getExerciseEvent();
    }
    if(medicationTab.value == true){
      getMedication();
    }
    if(genrealEventTab.value == true){
      getGeneralEvent();
    }
  };

  const getAllergy = async () => {
    base
      .get(`/v2/profiles/allergy/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
          if (response.data?.status === "success") {
            dataList.value = response.data.data;

            categoriesList.value = [];
            dataSeriesList.value = [];
            datasets.value = [];
            dataList.value.forEach((d) => {
              const date = formatDate(d.created_on);
              const { description } = d;
              categoriesList.value.push(date);
              dataSeriesList.value.push(parseInt(description));
            });

            generateDataSet("Description", dataSeriesList);

            datasets.value.push(
              graphData.value
            );

            updateChart();
          }
        })
        .catch((e) => {
          console.error("getAllergy Error", e);
        });
  };

  const getMedicalEvent = async () => {
    emitDataID('55');
    base
      .get(`/v2/profiles/medical-event/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          categoriesList.value = [];
          dataSeriesList.value = [];
          dataSeriesList2.value = [];
          dataSeriesList3.value = [];
          dataSeriesList4.value = [];
          dataSeriesList5.value = [];
          dataSeriesList6.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const {
              description,
              comment,
              current_treatment,
              complications,
              date_of_treatment,
              resolved,
            } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(description));
            dataSeriesList2.value.push(parseInt(comment));
            dataSeriesList3.value.push(parseInt(current_treatment));
            dataSeriesList4.value.push(parseInt(complications));
            dataSeriesList5.value.push(parseInt(date_of_treatment));
            dataSeriesList6.value.push(parseInt(resolved));
          });

          generateDataSet("Description", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Comment",
            dataSeriesList2,
            "#058EF0",
            "#058EF0"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Current Treatment",
            dataSeriesList3,
            "#FFD447",
            "#FFD447"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Complications",
            dataSeriesList4,
            "#7BC559",
            "#7BC559"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Date of Treatment",
            dataSeriesList5,
            "#7BC559",
            "#7BC559"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Resolved",
            dataSeriesList6,
            "#7BC559",
            "#7BC559"
          );

          datasets.value.push(
            graphData.value
          );

        updateChart();
      }
    })
    .catch((e) => {
      console.error("getMedicalEvent Error", e);
    });
  };

  const getSurgeryEvent = async () => {
    emitDataID('56');
    base
      .get(`/v2/profiles/surgery-event/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          categoriesList.value = [];
          dataSeriesList.value = [];
          dataSeriesList2.value = [];
          dataSeriesList3.value = [];
          dataSeriesList4.value = [];
          dataSeriesList5.value = [];
          dataSeriesList6.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const {
              description,
              comment,
              current_treatment,
              complications,
              date_of_treatment,
              resolved,
            } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(description));
            dataSeriesList2.value.push(parseInt(comment));
            dataSeriesList3.value.push(parseInt(current_treatment));
            dataSeriesList4.value.push(parseInt(complications));
            dataSeriesList5.value.push(parseInt(date_of_treatment));
            dataSeriesList6.value.push(parseInt(resolved));
          });

          generateDataSet("Description", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Comment",
            dataSeriesList2,
            "#058EF0",
            "#058EF0"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Current Treatment",
            dataSeriesList3,
            "#FFD447",
            "#FFD447"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Complications",
            dataSeriesList4,
            "#7BC559",
            "#7BC559"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Date of Treatment",
            dataSeriesList5,
            "#7BC559",
            "#7BC559"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Resolved",
            dataSeriesList6,
            "#7BC559",
            "#7BC559"
          );

          datasets.value.push(
            graphData.value
          );
          updateChart();
        }
      })
      .catch((e) => {
        console.error("getSurgeryEvent Error", e);
      });
  };

  const getFamilyEvent = async () => {
    emitDataID('57');
    base
      .get(`/v2/profiles/family-event/get/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          categoriesList.value = [];
          dataSeriesList.value = [];
          dataSeriesList2.value = [];
          dataSeriesList3.value = [];
          dataSeriesList4.value = [];
          dataSeriesList5.value = [];
          dataSeriesList6.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const {
              description,
              comment,
              current_treatment,
              complications,
              date_of_treatment,
              resolved,
            } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(description));
            dataSeriesList2.value.push(parseInt(comment));
            dataSeriesList3.value.push(parseInt(current_treatment));
            dataSeriesList4.value.push(parseInt(complications));
            dataSeriesList5.value.push(parseInt(date_of_treatment));
            dataSeriesList6.value.push(parseInt(resolved));
          });

          generateDataSet("Description", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Comment",
            dataSeriesList2,
            "#058EF0",
            "#058EF0"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Current Treatment",
            dataSeriesList3,
            "#FFD447",
            "#FFD447"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Complications",
            dataSeriesList4,
            "#7BC559",
            "#7BC559"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Date of Treatment",
            dataSeriesList5,
            "#7BC559",
            "#7BC559"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Resolved",
            dataSeriesList6,
            "#7BC559",
            "#7BC559"
          );

          datasets.value.push(
            graphData.value
          );
          updateChart();
        }
      })
      .catch((e) => {
        console.error("getFamilyEvent Error", e);
      });
  };

  const getExerciseEvent = async () => {
    emitDataID('58');
    base
      .get(`/v2/profiles/exercise-event/get/?secure_pin=${props.securityCode}`, {
        date: fromDate.value,
        date_of_last_episode: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          categoriesList.value = [];
          dataSeriesList.value = [];
          dataSeriesList2.value = [];
          dataSeriesList3.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const {
              description,
              date_of_last_episode,
              no_of_concussions,
            } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(description));
            dataSeriesList2.value.push(parseInt(date_of_last_episode));
            dataSeriesList3.value.push(parseInt(no_of_concussions));
          });

          generateDataSet("Description", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Date of Last Episode",
            dataSeriesList2,
            "#058EF0",
            "#058EF0"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "No of Concussions",
            dataSeriesList3,
            "#FFD447",
            "#FFD447"
          );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getExerciseEvent Error", e);
      });
  };

  const getMedication = async () => {
    emitDataID('59');
    base
      .get(`/v2/profiles/medication/get/?secure_pin=${props.securityCode}`, {
        date: fromDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          categoriesList.value = [];
          dataSeriesList.value = [];
          dataSeriesList2.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const {
              description,
              dose,
            } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(description));
            dataSeriesList2.value.push(parseInt(dose));
          });

          generateDataSet("Description", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Dose",
            dataSeriesList2,
            "#058EF0",
            "#058EF0"
          );

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getMedication Error", e);
      });
  };

  const getGeneralEvent = async () => {
    emitDataID('60');
    base
      .get(`/v2/profiles/general-event/get/?secure_pin=${props.securityCode}`, {
        date: fromDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.data;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const {
              description,
            } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(description));
          });

          generateDataSet("Description", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getGeneralEvent Error", e);
      });
  };

  onMounted(() => {
    nextTick(function () {
      ctx.value = document.getElementById("line-chart").getContext("2d");
      generateGraph(ctx.value, chartConfig.value);

      getCurrentDate();
      getCurrentYear();
    });
  });

</script>
<style>
#chart-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 2000px;
}
</style>

