<template>
  <HeaderShape></HeaderShape>

  <section class="sectionView -mt-5 text-paragraph-500">
    <div class="container mx-auto px-5">
      <!-- HEADER -->
      <div class="headerDiv">
        <base-card class="col-span-4 bg-white grid grid-cols-6">
          <img
            class="h-28 w-28 self-center"
            src="https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/activity.png"
          />
          <div class="col-span-5 justify-center self-center">
            <p class="headingOne font-bold text-paragraph-500">Pain Profile</p>
            <p
              class="mt-3 text-sm"
            >View and track your pain level before, during and after exercise.</p>
          </div>
        </base-card>
        <base-card class="col-span-2 mx-auto grid grid-cols-2 content-center">
          <p class="headingOne text-lg font-normal self-center">
            Add
            <span class="font-bold">Pain</span> Data
          </p>

          <div>
            <button
              @click="openModal()"
              class="flex primaryBtn h-12 w-12 float-right mx-auto rounded-full self-center"
            >
              <span class="text-4xl self-center">+</span>
            </button>
          </div>
        </base-card>

        <!-- select dates -->
        <!-- <base-card class="col-span-2 grid grid-cols-2 ">
          <div class="self-center">
            <p class="headingOne text-lg text-gray-900 font-bold ">Select Date Range</p>
            <button class="primaryBtn text-xs mr-3 my-5">This Year</button>
            <button class="primaryBtn text-xs my-5">This Month</button>
          </div>
          <div class="self-center">
            <p class="headingOne text-lg text-gray-900 font-bold ">Select Custom Date Range</p>
            <button class="primaryBtn text-xs my-5">Select Range</button>

          </div>
        </base-card>-->
      </div>

      <!-- CHARTS -->
      <PainCharts></PainCharts>
    </div>
  </section>

  <!-- MODAL -->
  <add-data-modal v-if="modalOpen">
    <template v-slot:header>Pain Score Profile</template>
    <template v-slot:content>
      <PainModal></PainModal>
    </template>
    <template v-slot:footer>
      <button class="secondaryBtn w-40" @click="closeModal">Close</button>
    </template>
    <template v-slot:background>
      <div v-if="modalOpen" class="opacity-75 fixed inset-0 z-40 bg-black"></div>
    </template>
  </add-data-modal>
</template>

<script>
import PainModal from "../components/modals/PainModal.vue";
import PainCharts from "../components/charts/PainCharts.vue";

import BaseCard from "../assets/BaseCard.vue";
import HeaderShape from "../assets/HeaderShape.vue";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { computed } from "vue";

export default {
  components: {
    PainModal,
    PainCharts,
    BaseCard,
    HeaderShape,
  },
  name: "PainProfile",
  inheritAttrs: false,

  data() {
    return {
      selected: "Select data type",
    };
  },

  setup() {
    // modal
    const modalOpen = ref(false);

    function openModal() {
      modalOpen.value = true;
    }

    function closeModal() {
      modalOpen.value = false;
    }

    // custom date range
    const range = {
      start: new Date(),
      end: new Date(),
    };

    // date
    const autoDate = ref();
    onMounted(() => {
      const startDate = new Date();
      const endDate = new Date(new Date().setDate(startDate.getDate() + 7));
      autoDate.value = [startDate, endDate];
    });

    // get user
    const store = useStore();
    const user = computed(() => store.getters["auth/getUser"]);

    return {
      // date
      range,

      // user
      user,
      autoDate,

      //  modal
      modalOpen,
      openModal,
      closeModal,
    };
  },
};
</script>

<style lang="postcss" scoped>
</style> 
