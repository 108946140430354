<template>
  <div class="relative my-2 mx-auto w-full h-full text-paragraph-500">
    <!-- modal content-->
    <div
      class="border-0 rounded shadow-md relative flex flex-col w-full bg-[#F8F8F8] outline-none focus:outline-none p-5 h-full"
    >
      <div class="flex justify-center">
        <img :src="profileSection.src" alt class="self-center image h-24 w-24" />
      </div>
      <!--modal header-->
      <div class="flex items-start justify-between pb-0 pt-5 rounded-t mx-auto">
        <h3 class="headingSections tracking-wide">{{ profileSection.title }} Profile</h3>
      </div>
      <!--modal body-->
      <div class="mx-10 pb-5 flex flex-grow">
        <div class="mt-5 gap-5">
          <div class="flex text-xs">
            <p class="self-center leading-5 text-center">{{ profileSection.desc }}</p>
          </div>
        </div>
      </div>
      <!-- modal footer-->
      <div class="modalFooter">
        <router-link class="w-full mx-10" :to="profileSection.to">
          <button class="w-full primaryBtn text-xs">{{ profileSection.btnText }}</button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  name: "profileSection",

  props: ["profileSection"],
};
</script>

<style lang="postcss" scoped >
</style>
