<template>
  <ConfirmationModal v-if="idNumberModal === true">
    <template v-slot:header>Enter your ID number</template>
    <template v-slot:content>
      <div class="form-control w-full mb-3">
        <base-input
          v-model="idNumber"
          refKey="idNumber"
          required
          noDetails
          label="ID Number"
        >
        </base-input>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex justify-around">
        <button
          class="primaryBtn w-40"
          @click="
            getBloodResults();
          "
        >
          Enter
        </button>
      </div>
    </template>
  </ConfirmationModal>

  <!-- Dates -->
  <div class="grid grid-cols-2 gap-5 mb-8 font-paragraph-500">
    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-4 gap-2">
        <h1 class="headingOne text-base self-center font-normal col-span-2">
          Select Date Range
        </h1>
        <button
          class="secondaryBtn text-xs focus:bg-[#035795] focus:border-[#035795] active:bg-[#035795]"
          @click="getCurrentMonth()"
        >
          This Month
        </button>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="getCurrentYear()"
        >
          This Year
        </button>
      </div>
    </div>

    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-3 gap-2">
        <base-date-time-picker
          v-model="fromDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <base-date-time-picker
          v-model="toDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="customizeDateRange()"
        >
        Customize Date Range
        </button>
      </div>
    </div>
  </div>

  <div v-if="!loading" class="grid grid-cols-1 gap-5 mb-8 font-paragraph-500">
      <div class="bg-white rounded p-5 shadow-xl">
        <div class="grid grid-cols-2 gap-2">
          <div class="grid grid-cols-3 gap-2">
            <div v-if="initialAmpathConsent">
              <p class="w-32 h-30 mx-auto">Partnered with</p>
              <img src="../../assets/img/ampath.png" alt class="rounded w-32 h-30 mx-auto" />
            </div>
            <div v-else>
              <img src="../../assets/img/ampath.png" alt class="rounded w-32 h-30 mx-auto" />
            </div>
            <div v-if="!initialAmpathConsent">
              <div style="grid-column: span 2;">
                <p class="text-gray-500">Your Ampath blood results can be loaded into your Assic Health Profile</p>
                <label class="label justify-start">
                  <input
                    type="checkbox"
                    class="custom-checkbox checkbox-sm mr-2 rounded-md"
                    v-model="ampathConsent"
                    @click="giveAmpathConsent()"
                  />
                  <span class="label-text text-gray-500 text-xs font-light">Consent that my Ampath blood results will be shared with Assic Health</span>
                </label>
              </div>

            </div>
          </div>
          <div class="grid grid-cols-2 gap-2" style="align-content: end;">
            <div></div>
            <div v-if="!initialAmpathConsent">
              <button
                class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
                @click="openIDModal()"
              >
                Get Blood Results
              </button>
            </div>
            <div v-else>
              <button
                class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
                @click="openIDModal()"
              >
                Refresh Blood Results
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  <!-- Tabs -->
  <div class="grid grid-cols-5 gap-2 mb-4">
    <button class="btnTab" @click="getTotalProtein(); determineCurrentTab('TotalProtein')">Total Protein</button>
    <button class="btnTab" @click="getCovid19(); determineCurrentTab('COVID-19')">COVID-19</button>
    <button class="btnTab" @click="getAlbumimSerium(); determineCurrentTab('AlbumimSerium')">Albumim - Serium</button>
    <button class="btnTab" @click="getUreaNitrogin(); determineCurrentTab('UreaNitrogin')">Urea Nitrogin (BUN)</button>
    <button class="btnTab" @click="getProteinSerum(); determineCurrentTab('ProteinSERUM')">Protein - SERUM</button>
    <button class="btnTab" @click="getProteinElectrophoresis(); determineCurrentTab('ProteinElectrophoresis')">Protein Electrophoresis</button>
    <button class="btnTab" @click="getUlricAcid(); determineCurrentTab('UlricAcid')">Ulric Acid</button>
    <button class="btnTab" @click="getCholesterolRandom(); determineCurrentTab('CholesterolRandom')">Cholesterol - Random</button>
    <button class="btnTab" @click="getCholesterolFasting(); determineCurrentTab('CholesterolFasting')">Cholesterol - Fasting</button>
    <button class="btnTab" @click="getHDL(); determineCurrentTab('HDL')">HDL</button>
    <button class="btnTab" @click="getLDL(); determineCurrentTab('LDL')">LDL</button>
    <button class="btnTab" @click="getTG(); determineCurrentTab('TG')">TG</button>
    <button class="btnTab" @click="getGlucoseRandom(); determineCurrentTab('glucoseRandom')">Glucose Random</button>
    <button class="btnTab" @click="getFastingGlucose(); determineCurrentTab('fastingGlucose')">Fasting Glucose</button>
    <button class="btnTab" @click="getHbAlc(); determineCurrentTab('HbAlc')">HbAlc</button>
    <button class="btnTab" @click="getGTT(); determineCurrentTab('GTT')">GTT</button>
    <button class="btnTab" @click="getMicroalbumin(); determineCurrentTab('Microalbumin')">Microalbumin</button>
    <button class="btnTab" @click="getFePlus(); determineCurrentTab('FePlus')">Fe+</button>
    <button class="btnTab" @click="getTransferrin(); determineCurrentTab('Transferrin')">Transferrin</button>
    <button class="btnTab" @click="getFerritin(); determineCurrentTab('Ferritin')">Ferritin</button>
    <button class="btnTab" @click="getFeBindingCapacity(); determineCurrentTab('FeBindingCapacity')">% Fe Binding Capacity</button>
    <button class="btnTab" @click="getTIBC(); determineCurrentTab('TIBC')">TIBC</button>
    <button class="btnTab" @click="getMgPlus(); determineCurrentTab('MgPlus')">Mg+</button>
    <button class="btnTab" @click="getPi(); determineCurrentTab('PiMinus')">Pi-</button>
    <button class="btnTab" @click="getCaPlusTotal(); determineCurrentTab('CaPlusTotal')">Ca+ Total</button>
    <button class="btnTab" @click="getCaPlusCorrected(); determineCurrentTab('CaPlusCorrected')">Ca+ Corrected</button>
    <button class="btnTab" @click="getCaPlusAlb(); determineCurrentTab('CaPlusAlb')">Ca+ Alb</button>
    <button class="btnTab" @click="getVitD(); determineCurrentTab('VitD')">Vit D</button>
    <button class="btnTab" @click="getB12(); determineCurrentTab('B12')">B12</button>
    <button class="btnTab" @click="getRBCFolate(); determineCurrentTab('RBCFolate')">RBC Folate</button>
    <button class="btnTab" @click="getSerumFolate(); determineCurrentTab('SerumFolate')">Serum Folate</button>
    <button class="btnTab" @click="getSodium(); determineCurrentTab('Sodium')">Sodium</button>
    <button class="btnTab" @click="getPotassium(); determineCurrentTab('Potassium')">Potassium</button>
    <button class="btnTab" @click="getUrea(); determineCurrentTab('Urea')">Urea</button>
    <button class="btnTab" @click="getCreatinnineUrine(); determineCurrentTab('CreatinnineUrine')">Creatinnine - URINE</button>
    <button class="btnTab" @click="getOsmolality(); determineCurrentTab('Osmolality')">Osmolality</button>
    <button class="btnTab" @click="getCRP(); determineCurrentTab('CRP')">CRP</button>
    <button class="btnTab" @click="getInsulinFasting(); determineCurrentTab('InsulinFasting')">Insulin - FASTING</button>
    <button class="btnTab" @click="getCortisol(); determineCurrentTab('Cortisol')">Cortisol</button>
    <button class="btnTab" @click="getTSH(); determineCurrentTab('TSH')">TSH</button>
    <button class="btnTab" @click="getT4(); determineCurrentTab('T4')">T4</button>
    <button class="btnTab" @click="getT3(); determineCurrentTab('T3')">T3</button>
    <button class="btnTab" @click="getThyroglobulin(); determineCurrentTab('Thyroglobulin')">Thyroglobulin</button>
    <button class="btnTab" @click="getThyroidAb(); determineCurrentTab('ThyroidAb')">Thyroid Ab</button>
    <button class="btnTab" @click="getTestosterone(); determineCurrentTab('Testosterone')">Testosterone</button>
    <button class="btnTab" @click="getOestrogenEstrogen(); determineCurrentTab('OestrogenEstrogen')">Oestrogen/Estrogen</button>
    <button class="btnTab" @click="getGHRandom(); determineCurrentTab('GHRandom')">GH - RANDOM</button>
    <button class="btnTab" @click="getPTH(); determineCurrentTab('PTH')">PTH</button>
    <button class="btnTab" @click="getBHCGQuantification(); determineCurrentTab('BHCGQuantification')">B-HCG Quantification</button>
    <button class="btnTab" @click="getFSHLH(); determineCurrentTab('FSHLH')">FSH/LH</button>
    <button class="btnTab" @click="getCK(); determineCurrentTab('CK')">CK</button>
    <button class="btnTab" @click="getMyoglobin(); determineCurrentTab('Myoglobin')">Myoglobin</button>
    <button class="btnTab" @click="getCKMB(); determineCurrentTab('CKMB')">CK MB</button>
    <button class="btnTab" @click="getTroponins(); determineCurrentTab('Troponins')">Troponins</button>
    <button class="btnTab" @click="getTotalBilirubin(); determineCurrentTab('TotalBilirubin')">Total Bilirubin</button>
    <button class="btnTab" @click="getConjugatedBilirubin(); determineCurrentTab('ConjugatedBilirubin')">Conjugated bilirubin</button>
    <button class="btnTab" @click="getALT(); determineCurrentTab('ALT')">ALT</button>
    <button class="btnTab" @click="getAST(); determineCurrentTab('AST')">AST</button>
    <button class="btnTab" @click="getALP(); determineCurrentTab('ALP')">ALP</button>
    <button class="btnTab" @click="getGGT(); determineCurrentTab('GGT')">GGT</button>
    <button class="btnTab" @click="getLDH(); determineCurrentTab('LDH')">LDH</button>
    <button class="btnTab" @click="getImmunoglobulins(); determineCurrentTab('Immunoglobulins')">Immunoglobulins</button>
    <button class="btnTab" @click="getAmylaseSerum(); determineCurrentTab('AmylaseSerum')">Amylase - SERUM</button>
    <button class="btnTab" @click="getLipaseSerum(); determineCurrentTab('LipaseSerum')">Lipase - SERUM</button>
    <button class="btnTab" @click="getHb(); determineCurrentTab('Hb')">Hb</button>
    <button class="btnTab" @click="getMCV(); determineCurrentTab('MCV')">MCV</button>
    <button class="btnTab" @click="getWCC(); determineCurrentTab('WCC')">WCC</button>
    <button class="btnTab" @click="getPlatelet(); determineCurrentTab('Platelet')">Platelet</button>
    <button class="btnTab" @click="getDifferentialCount(); determineCurrentTab('tDifferentialCount')">Differential Count</button>
    <button class="btnTab" @click="getSmear(); determineCurrentTab('Smear')">Smear</button>
    <button class="btnTab" @click="getRetics(); determineCurrentTab('Retics')">Retics</button>
    <button class="btnTab" @click="getINR(); determineCurrentTab('INR')">INR</button>
    <button class="btnTab" @click="getPTT(); determineCurrentTab('PTT')">PTT</button>
    <button class="btnTab" @click="getDDimer(); determineCurrentTab('DDimer')">D dimer</button>
    <button class="btnTab" @click="getFibrinogen(); determineCurrentTab('Fibrinogen')">Fibrinogen</button>
    <button class="btnTab" @click="getESR(); determineCurrentTab('ESR')">ESR</button>
    <button class="btnTab" @click="getAllergyTests(); determineCurrentTab('AllergyTests')">Allergy Tests</button>
  </div>

  <!-- Chart -->
  <div
    class="chart bg-gray-300 relative rounded-lg rounded-t-none p-10 mb-5"
    id="chartWrapper"
  >
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, nextTick, defineEmits, defineProps } from "vue";
  import base from "../../services/api";
  import { formatISO } from "date-fns";
  import moment from "moment";
  import { Chart, registerables } from "chart.js";
  import { toast } from 'vue3-toastify';
  import ConfirmationModal from "@/assets/ConfirmationModal.vue";
  import BaseInput from "../../../src/assets/Form/BaseInput.vue";

  import BaseDateTimePicker from "../../../src/assets/Form/BaseDateTimePicker.vue";

  Chart.register(...registerables);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm"); // Consistent date formatting
  };

  const emit = defineEmits(["retrieveTabId"]);
  const emitDataID = async (id) => {
    emit("retrieveTabId", id);
  };

  const props = defineProps({
    securityCode: Number
  });


  const fromDate = ref("");
  const toDate = ref("");

  const graphData = {};
  //const dataArr = ref([]);
  const dataList = ref([]);
  const categoriesList = ref(null);
  const dataSeriesList = ref(null);

  const datasets = ref(null);

  const ctx = ref(null);

  const TotalProteinTab = ref(true);
  const Covid19Tab = ref(false);
  const AlbumimSeriumTab = ref(false);
  const UreaNitroginTab = ref(false);
  const ProteinSerumTab = ref(false);
  const ProteinElectrophoresisTab = ref(false);
  const UlricAcidTab = ref(false);
  const CholesterolRandomTab = ref(false);
  const CholesterolFastingTab = ref(false);
  const HDLTab = ref(false);
  const LDLTab = ref(false);
  const TGTab = ref(false);
  const GlucoseRandomTab = ref(false);
  const FastingGlucoseTab = ref(false);
  const HbAlcTab = ref(false);
  const GTTTab = ref(false);
  const MicroalbuminTab = ref(false);
  const FePlusTab = ref(false);
  const TransferrinTab = ref(false);
  const FerritinTab = ref(false);
  const FeBindingCapacityTab = ref(false);
  const TIBCTab = ref(false);
  const MgPlusTab = ref(false);
  const PiTab = ref(false);
  const CaPlusTotalTab = ref(false);
  const CaPlusCorrectedTab = ref(false);
  const CaPlusAlbTab = ref(false);
  const VitDTab = ref(false);
  const B12Tab = ref(false);
  const RBCFolateTab = ref(false);
  const SerumFolateTab = ref(false);
  const SodiumTab = ref(false);
  const PotassiumTab = ref(false);
  const UreaTab = ref(false);
  const CreatinnineUrineTab = ref(false);
  const OsmolalityTab = ref(false);
  const CRPTab = ref(false);
  const InsulinFastingTab = ref(false);
  const CortisolTab = ref(false);
  const TSHTab = ref(false);
  const T4Tab = ref(false);
  const T3Tab = ref(false);
  const ThyroglobulinTab = ref(false);
  const ThyroidAbTab = ref(false);
  const TestosteroneTab = ref(false);
  const OestrogenEstrogenTab = ref(false);
  const GHRandomTab = ref(false);
  const PTHTab = ref(false);
  const BHCGQuantificationTab = ref(false);
  const FSHLHTab = ref(false);
  const CKTab = ref(false);
  const MyoglobinTab = ref(false);
  const CKMBTab = ref(false);
  const TroponinsTab = ref(false);
  const TotalBilirubinTab = ref(false);
  const ConjugatedBilirubinTab = ref(false);
  const ALTTab = ref(false);
  const ASTTab = ref(false);
  const ALPTab = ref(false);
  const GGTTab = ref(false);
  const LDHTab = ref(false);
  const ImmunoglobulinsTab = ref(false);
  const AmylaseSerumTab = ref(false);
  const LipaseSerumTab = ref(false);
  const HbTab = ref(false);
  const MCVTab = ref(false);
  const WCCTab = ref(false);
  const PlateletTab = ref(false);
  const DifferentialCountTab = ref(false);
  const SmearTab = ref(false);
  const ReticsTab = ref(false);
  const INRTab = ref(false);
  const PTTTab = ref(false);
  const DDimerTab = ref(false);
  const FibrinogenTab = ref(false);
  const ESRTab = ref(false);
  const AllergyTestsTab = ref(false);

  const chartConfig = computed(() => { return {
    type: "line", // Define the type of chart
    data: {
      labels: categoriesList.value, // Use dynamic list of labels (dates)
      datasets: datasets.value, // Dynamic datasets
    },
    options: {
      maintainAspectRatio: true,
      responsive: true,
      scales: {
        x: [{
          type: 'time',
          time: {
            unit: 'second',
            displayFormats: {
              second: 'YYYY-MM-DD HH:mm'
            }
          },
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        }],
        y: {
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        },
      },
      title: {
        display: false,
      },
      legend: {
        labels: {
          fontColor: "white",
        },
        align: "end",
        position: "bottom",
      },
      tooltips: {
        mode: "index", // Tooltip mode
        intersect: false,
      },
      hover: {
        mode: "nearest", // Hover behavior
        intersect: true,
      },
    },
  }});

  let myLine = null;

  const generateGraph = async (ctx, chartConfig) => {
    myLine = new Chart(ctx, chartConfig);
  }

  const getCurrentDate = async () => {
    const currentDate = new Date();
    toDate.value = formatISO(currentDate);
  }

  const idNumberModal = ref(false)
  const idNumber = ref(null)

  const openIDModal = async () => {
    idNumberModal.value = true
  }

  const getBloodResults = async () => {
      base
        .post('/v2/profiles/ampath/', {
          id_number: idNumber.value
        })
        .then((response) => {
          if (response.data.status == 'success') {
            toast('Blood results imported from Ampath', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          idNumberModal.value = false
        })
        .catch((e) => {
          if (e.response.data.message == 'No results found') {
            toast('No results found', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast('Unable to import blood results from Ampath', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
          idNumberModal.value = false
        });
      refreshTabs();
  };

  const ampathConsent = ref(false);
  const initialAmpathConsent = ref(false);
  const loading = ref(true);

  const checkAmpathConsent = async () => {
    base
      .get('/v2/user/ampath/consent/')
      .then((response) => {
        ampathConsent.value = response.data.data
        initialAmpathConsent.value = response.data.data
        loading.value = false
      })
      .catch(() => {
        toast('Unable to get Ampath consent', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
  }

  const giveAmpathConsent = async () => {
    base
      .post('/v2/user/ampath/consent/')
      .then((response) => {
        if (response.data.status == 'success') {
          ampathConsent.value = true
          toast('Consent given for Ampath', {
              type: 'success',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch(() => {
        toast('Unable to give consent for Ampath', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
  }

  checkAmpathConsent()

  const getCurrentYear = async () => {
    const firstDayYear = new Date(new Date().getFullYear(), 0, 1);
      fromDate.value = formatISO(firstDayYear);
      if(props.securityCode){
        refreshTabs();
      }
  };

  const customizeDateRange = async () => {
    if(props.securityCode){
        refreshTabs();
    }
  };

  const getCurrentMonth = async () => {
    const firstDayMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );

      fromDate.value = formatISO(firstDayMonth);
      if(props.securityCode){
        refreshTabs();
      }
  };

  const generateDataSet = async (label,
    data,
    backgroundColor = "#F04248",
    borderColor = "#F04248") => {
      graphData.value = {
          label: label,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          data: data,
          fill: false
        }
  };

  const updateChart = async () => {
    myLine.data.labels = categoriesList.value;
    myLine.data.datasets = datasets.value;
    myLine.update();
  };

  const determineCurrentTab = async (tabType) => {

    /*
      This function is reponsible ensuring that the user does not get taken to a tab they do not wish to view
      when adding data to customizing dates
    */
    if(tabType == "TotalProtein"){
      TotalProteinTab.value = true;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "COVID-19"){
      Covid19Tab.value = true;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "AlbumimSerium"){
      AlbumimSeriumTab.value = true;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "UreaNitrogin"){
      UreaNitroginTab.value = true;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "ProteinSERUM"){
      ProteinSerumTab.value = true;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "ProteinElectrophoresis"){
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "UlricAcid"){
      UlricAcidTab.value = true;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "CholesterolRandom"){
      CholesterolRandomTab.value = true;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "CholesterolFasting"){
      CholesterolFastingTab.value = true;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "HDL"){
      HDLTab.value = true;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "LDL"){
      LDLTab.value = true;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "TG"){
      TGTab.value = true;
      LDLTab.value = false;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;

    }

    if(tabType == "glucoseRandom"){
      GlucoseRandomTab.value = true;
      LDLTab.value = false;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      TGTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "fastingGlucose"){
      FastingGlucoseTab.value = true;
      LDLTab.value = false;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "HbAlc"){
      HbAlcTab.value = true;
      LDLTab.value = false;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "GTT"){
      GTTTab.value = true;
      LDLTab.value = false;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "Microalbumin"){
      MicroalbuminTab.value = true;
      LDLTab.value = false;
      TotalProteinTab.value = false;
      Covid19Tab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }

    if(tabType == "FePlus"){
      FePlusTab.value = true;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Transferrin"){
      TransferrinTab.value = true;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Ferritin"){
      FerritinTab.value = true;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "FeBindingCapacity"){
      FeBindingCapacityTab.value = true;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "TIBC"){
      TIBCTab.value = true;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "MgPlus"){
      MgPlusTab.value = true;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      PiTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "PiMinus"){
      PiTab.value = true;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      CaPlusTotalTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "CaPlusTotal"){
      CaPlusTotalTab.value = true;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusCorrectedTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "CaPlusCorrected"){
      CaPlusCorrectedTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "CaPlusAlb"){
      CaPlusAlbTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "VitD"){
      VitDTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "B12"){
      B12Tab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "RBCFolate"){
      RBCFolateTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "SerumFolate"){
      SerumFolateTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Sodium"){
      SodiumTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Potassium"){
      PotassiumTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Urea"){
      UreaTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "CreatinnineUrine"){
      CreatinnineUrineTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Osmolality"){
      OsmolalityTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "CRP"){
      CRPTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "InsulinFasting"){
      InsulinFastingTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Cortisol"){
      CortisolTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "TSH"){
      TSHTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "T4"){
      T4Tab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "T3"){
      T3Tab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Thyroglobulin"){
      ThyroglobulinTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "ThyroidAb"){
      ThyroidAbTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Testosterone"){
      TestosteroneTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "OestrogenEstrogen"){
      OestrogenEstrogenTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "GHRandom"){
      GHRandomTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "PTH"){
      PTHTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "BHCGQuantification"){
      BHCGQuantificationTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "FSHLH"){
      FSHLHTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "CK"){
      CKTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Myoglobin"){
      MyoglobinTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "CKMB"){
      CKMBTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Troponins"){
      TroponinsTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "TotalBilirubin"){
      TotalBilirubinTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "ConjugatedBilirubin"){
      ConjugatedBilirubinTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "ALT"){
      ALTTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "AST"){
      ASTTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "ALP"){
      ALPTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "GGT"){
      GGTTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      LDHTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "LDH"){
      LDHTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Immunoglobulins"){
      ImmunoglobulinsTab.value = true;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      LDHTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "AmylaseSerum"){
      AmylaseSerumTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "LipaseSerum"){
      LipaseSerumTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Hb"){
      HbTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "MCV"){
      MCVTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "WCC"){
      WCCTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Platelet"){
      PlateletTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "DifferentialCount"){
      DifferentialCountTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Smear"){
      SmearTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Retics"){
      ReticsTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "INR"){
      INRTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "PTT"){
      PTTTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "DDimer"){
      DDimerTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "Fibrinogen"){
      FibrinogenTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      ESRTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "ESR"){
      ESRTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      AllergyTestsTab.value = false;
    }
    if(tabType == "AllergyTests"){
      AllergyTestsTab.value = true;
      LDHTab.value = false;
      CaPlusTotalTab.value = false;
      Covid19Tab.value = false;
      TotalProteinTab.value = false;
      AlbumimSeriumTab.value = false;
      UreaNitroginTab.value = false;
      ProteinSerumTab.value = false;
      ProteinElectrophoresisTab.value = false;
      UlricAcidTab.value = false;
      CholesterolRandomTab.value = false;
      CholesterolFastingTab.value = false;
      HDLTab.value = false;
      LDLTab.value = false;
      TGTab.value = false;
      GlucoseRandomTab.value = false;
      FastingGlucoseTab.value = false;
      HbAlcTab.value = false;
      GTTTab.value = false;
      MicroalbuminTab.value = false;
      FePlusTab.value = false;
      TransferrinTab.value = false;
      FerritinTab.value = false;
      FeBindingCapacityTab.value = false;
      TIBCTab.value = false;
      MgPlusTab.value = false;
      PiTab.value = false;
      CaPlusAlbTab.value = false;
      VitDTab.value = false;
      B12Tab.value = false;
      RBCFolateTab.value = false;
      SerumFolateTab.value = false;
      SodiumTab.value = false;
      PotassiumTab.value = false;
      UreaTab.value = false;
      CreatinnineUrineTab.value = false;
      OsmolalityTab.value = false;
      CRPTab.value = false;
      InsulinFastingTab.value = false
      CortisolTab.value = false;
      TSHTab.value = false;
      T4Tab.value = false;
      T3Tab.value = false;
      ThyroglobulinTab.value = false;
      ThyroidAbTab.value = false;
      TestosteroneTab.value = false;
      OestrogenEstrogenTab.value = false;
      GHRandomTab.value = false;
      PTHTab.value = false;
      BHCGQuantificationTab.value = false;
      FSHLHTab.value = false;
      CKTab.value = false;
      MyoglobinTab.value = false;
      CKMBTab.value = false;
      TroponinsTab.value = false;
      TotalBilirubinTab.value = false;
      ConjugatedBilirubinTab.value = false;
      ALTTab.value = false;
      ASTTab.value = false;
      ALPTab.value = false;
      GGTTab.value = false;
      ImmunoglobulinsTab.value = false;
      AmylaseSerumTab.value = false;
      LipaseSerumTab.value = false;
      HbTab.value = false;
      MCVTab.value = false;
      WCCTab.value = false;
      PlateletTab.value = false;
      DifferentialCountTab.value = false;
      SmearTab.value = false;
      ReticsTab.value = false;
      INRTab.value = false;
      PTTTab.value = false;
      DDimerTab.value = false;
      FibrinogenTab.value = false;
      ESRTab.value = false;
    }
  }

  const refreshTabs = async () => {

    /*
      This function is reponsible for refreshing graphs when a user adds data or selects date ranges
      This is to ensure that data can instantly retrieved without the user needing to refresh the page everytime
    */

    if(TotalProteinTab.value == true){
      getTotalProtein();
    }
    if(Covid19Tab.value == true){
      getCovid19();
    }
    if(AlbumimSeriumTab.value == true){
      getAlbumimSerium();
    }
    if(UreaNitroginTab.value == true){
      getUreaNitrogin();
    }
    if(ProteinSerumTab.value == true){
      getProteinSerum();
    }
    if(ProteinElectrophoresisTab.value == true){
      getProteinElectrophoresis();
    }
    if(UlricAcidTab.value == true){
      getUlricAcid();
    }
    if(CholesterolRandomTab.value == true){
      getCholesterolRandom();
    }
    if(CholesterolFastingTab.value == true){
      getCholesterolFasting();
    }
    if(HDLTab.value == true){
      getHDL();
    }
    if(LDLTab.value == true){
      getLDL();
    }
    if(TGTab.value == true){
      getTG();
    }
    if(GlucoseRandomTab.value == true){
      getGlucoseRandom();
    }
    if(FastingGlucoseTab.value == true){
      getFastingGlucose();
    }
    if(HbAlcTab.value == true){
      getHbAlc();
    }
    if(GTTTab.value == true){
      getGTT();
    }
    if(MicroalbuminTab.value == true){
      getMicroalbumin();
    }
    if(FePlusTab.value == true){
      getFePlus();
    }
    if(TransferrinTab.value == true){
      getTransferrin();
    }
    if(FerritinTab.value == true){
      getFerritin();
    }
    if(FeBindingCapacityTab.value == true){
      getFeBindingCapacity();
    }
    if(TIBCTab.value == true){
      getTIBC();
    }
    if(MgPlusTab.value == true){
      getMgPlus();
    }
    if(PiTab.value == true){
      getPi();
    }
    if(CaPlusTotalTab.value == true){
      getCaPlusTotal();
    }
    if(CaPlusCorrectedTab.value == true){
      getCaPlusCorrected();
    }
    if(CaPlusAlbTab.value == true){
      getCaPlusAlb();
    }
    if(VitDTab.value == true){
      getVitD();
    }
    if(B12Tab.value == true){
      getB12();
    }
    if(RBCFolateTab.value == true){
      getRBCFolate();
    }
    if(SerumFolateTab.value == true){
      getSerumFolate();
    }
    if(SodiumTab.value == true){
      getSodium();
    }
    if(PotassiumTab.value == true){
      getPotassium();
    }
    if(UreaTab.value == true){
      getUrea();
    }
    if(CreatinnineUrineTab.value == true){
      getCreatinnineUrine();
    }
    if(OsmolalityTab.value == true){
      getOsmolality();
    }
    if(CRPTab.value == true){
      getCRP();
    }
    if(InsulinFastingTab.value == true){
      getInsulinFasting();
    }
    if(CortisolTab.value == true){
      getCortisol();
    }
    if(TSHTab.value == true){
      getTSH();
    }
    if(T4Tab.value == true){
      getT4();
    }
    if(T3Tab.value == true){
      getT3();
    }
    if(ThyroglobulinTab.value == true){
      getThyroglobulin();
    }
    if(ThyroidAbTab.value == true){
      getThyroidAb();
    }
    if(TestosteroneTab.value == true){
      getTestosterone();
    }
    if(OestrogenEstrogenTab.value == true){
      getOestrogenEstrogen();
    }
    if(GHRandomTab.value == true){
      getGHRandom();
    }
    if(PTHTab.value == true){
      getPTH();
    }
    if(BHCGQuantificationTab.value == true){
      getBHCGQuantification();
    }
    if(FSHLHTab.value == true){
      getFSHLH();
    }
    if(CKTab.value == true){
      getCK();
    }
    if(MyoglobinTab.value == true){
      getMyoglobin();
    }
    if(CKMBTab.value == true){
      getCKMB();
    }
    if(TroponinsTab.value == true){
      getTroponins();
    }
    if(TotalBilirubinTab.value == true){
      getTotalBilirubin();
    }
    if(ConjugatedBilirubinTab.value == true){
      getConjugatedBilirubin();
    }
    if(ALTTab.value == true){
      getALT();
    }
    if(ASTTab.value == true){
      getAST();
    }
    if(ALPTab.value == true){
      getALP();
    }
    if(GGTTab.value == true){
      getGGT();
    }
    if(LDHTab.value == true){
      getLDH();
    }
    if(ImmunoglobulinsTab.value == true){
      getImmunoglobulins();
    }
    if(AmylaseSerumTab.value == true){
      getAmylaseSerum();
    }
    if(LipaseSerumTab.value == true){
      getLipaseSerum();
    }
    if(HbTab.value == true){
      getHb();
    }
    if(MCVTab.value == true){
      getMCV();
    }
    if(WCCTab.value == true){
      getWCC();
    }
    if(PlateletTab.value == true){
      getPlatelet();
    }
    if(DifferentialCountTab.value == true){
      getDifferentialCount();
    }
    if(SmearTab.value == true){
      getSmear();
    }
    if(ReticsTab.value == true){
      getRetics();
    }
    if(INRTab.value == true){
      getINR();
    }
    if(PTTTab.value == true){
      getPTT();
    }
    if(DDimerTab.value == true){
      getDDimer();
    }
    if(FibrinogenTab.value == true){
      getFibrinogen();
    }
    if(ESRTab.value == true){
      getESR();
    }
    if(AllergyTestsTab.value == true){
      getAllergyTests();
    }
  };

  const getTotalProtein = async () => {
    emitDataID('88');
    base
      .post(`/v2/profiles/secure/get/88/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];


          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);
            const value = d.total_protein;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Total Protein: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getTotalProtein Error", e);
      });
  };

  const getCovid19 = async () => {
    emitDataID('89');
    base
      .post(`/profiles/secure/get/89/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.covid_19;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Covid-19: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
    })
    .catch((e) => {
      console.error("getCovid19 Error", e);
    });
  };

  const getAlbumimSerium = async () => {
    emitDataID('90');
    base
      .post(`/profiles/secure/get/90/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.albumin_serium;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Albumim Serium: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getAlbumimSerium Error", e);
      });
  };

  const getUreaNitrogin = async () => {
    emitDataID('91');
    base
      .post(`/profiles/secure/get/91/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.urea_nitrogen_b;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Urea Nitrogin: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getUreaNitrogin Error", e);
      });
  };

  const getProteinSerum = async () => {
    emitDataID('92');
    base
      .post(`/profiles/secure/get/92/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.protein_serum;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Protein Serum: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getProteinSerum Error", e);
      });
  };

  const getProteinElectrophoresis = async () => {
    emitDataID('93');
    base
      .post(`/profiles/secure/get/93/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.protein_electro;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Protein Electrophoresis: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getProteinElectrophoresis Error", e);
      });
  };

  const getUlricAcid = async () => {
    emitDataID('94');
    base
      .post(`/profiles/secure/get/94/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.uric_acid;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Ulric Acid: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getUlricAcid Error", e);
      });
  };

  const getCholesterolRandom = async () => {
    emitDataID('95');
    base
      .post(`/profiles/secure/get/95/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.cholesterol_ran;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Cholesterol Random: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getCholesterolRandom Error", e);
      });
  };

  const getCholesterolFasting = async () => {
    emitDataID('96');
    base
      .post(`/profiles/secure/get/96/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.cholesterol_fas;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Cholesterol Fasting: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getCholesterolFasting Error", e);
      });
  };

  const getHDL = async () => {
    emitDataID('97');
    base
      .post(`/profiles/secure/get/97/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.hdl;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("HDL: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getHDL Error", e);
      });
  };

  const getLDL = async () => {
    emitDataID('98');
    base
      .post(`/profiles/secure/get/98/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.ldl;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("LDL: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getLDL Error", e);
      });
  };

  const getTG = async () => {
    emitDataID('99');
    base
      .post(`/profiles/secure/get/99/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.tg;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("TG: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getTG Error", e);
      });
  };

  const getGlucoseRandom = async () => {
    emitDataID('100');
    base
      .post(`/profiles/secure/get/100/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.glucose_random;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Glucose Random: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getGlucoseRandom Error", e);
      });
  };

  const getFastingGlucose = async () => {
    emitDataID('101');
    base
      .post(`/profiles/secure/get/101/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.fasting_glucose;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Fasting Glucose: ", dataSeriesList)

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getFastingGlucose Error", e);
      });
  };

  const getHbAlc = async () => {
    emitDataID('102');
    base
      .post(`/profiles/secure/get/102/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.hba1c;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("HbAlc: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getHbAlc Error", e);
      });
  };

  const getGTT = async () => {
    emitDataID('103');
    base
      .post(`/profiles/secure/get/103/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.gtt;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("GTT: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getGTT Error", e);
      });
  };

  const getMicroalbumin = async () => {
    emitDataID('104');
    base
      .post(`/profiles/secure/get/104/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.microalbumin;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Microalbumin: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getMicroalbumin Error", e);
      });
  };

  const getFePlus = async () => {
    emitDataID('105');
    base
      .post(`/profiles/secure/get/105/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.fe;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("FePlus: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getFePlus Error", e);
      });
  };

  const getTransferrin = async () => {
    emitDataID('106');
    base
      .post(`/profiles/secure/get/106/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.transferrin;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Transferrin: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getTransferrin Error", e);
      });
  };

  const getFerritin = async () => {
    emitDataID('107');
    base
      .post(`/profiles/secure/get/107/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.ferritin;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Ferritin: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getFerritin Error", e);
      });
  };

  const getFeBindingCapacity = async () => {
    emitDataID('108');
    base
      .post(`/profiles/secure/get/108/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.fe_binding_capa;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Fe Binding Capacity: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getFeBindingCapacity Error", e);
      });
  };

  const getTIBC = async () => {
    emitDataID('109');
    base
      .post(`/profiles/secure/get/109/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.tibc;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("TIBC: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getTIBC Error", e);
      });
  };

  const getMgPlus = async () => {
    emitDataID('110');
    base
      .post(`/profiles/secure/get/110/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.mg;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Mg Plus: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getMgPlus Error", e);
      });
  };

  const getPi = async () => {
    emitDataID('111');
    base
      .post(`/profiles/secure/get/111/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.pi;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Pi: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getPi Error", e);
      });
  };

  const getCaPlusTotal = async () => {
    emitDataID('112');
    base
      .post(`/profiles/secure/get/112/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.ca_total;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Ca Plus Total: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getCaPlusTotal Error", e);
      });
  };

  const getCaPlusCorrected = async () => {
    emitDataID('113');
    base
      .post(`/profiles/secure/get/113/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.ca_corrected;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Ca Plus Corrected: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getCaPlusCorrected Error", e);
      });
  };

  const getCaPlusAlb = async () => {
    emitDataID('114');
    base
      .post(`/profiles/secure/get/114/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.ca_alb;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Ca Plus Alb: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getCaPlusAlb Error", e);
      });
  };

  const getVitD = async () => {
    emitDataID('115');
    base
      .post(`/profiles/secure/get/115/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.vit_d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Vit D: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getVitD Error", e);
      });
  };

  const getB12 = async () => {
    emitDataID('116');
    base
      .post(`/profiles/secure/get/116/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.b12;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("B12: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getB12 Error", e);
      });
  };

  const getRBCFolate = async () => {
    emitDataID('117');
    base
      .post(`/profiles/secure/get/117/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.rbc_folate;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("RBC Folate: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getRBCFolate Error", e);
      });
  };

  const getSerumFolate = async () => {
    emitDataID('118');
    base
      .post(`/profiles/secure/get/118/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.serum_folate;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Serum Folate: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getSerumFolate Error", e);
      });
  };

  const getSodium = async () => {
    emitDataID('119');
    base
      .post(`/profiles/secure/get/119/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.sodium;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Sodium: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getSodium Error", e);
      });
  };

  const getPotassium = async () => {
    emitDataID('120');
    base
      .post(`/profiles/secure/get/120/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.potassium;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Potassium: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getPotassium Error", e);
      });
  };

  const getUrea = async () => {
    emitDataID('121');
    base
      .post(`/profiles/secure/get/121/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.urea;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Urea: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getUrea Error", e);
      });
  };

  const getCreatinnineUrine = async () => {
    emitDataID('122');
    base
      .post(`/profiles/secure/get/122/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.creatinine_urin;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Creatinnine Urine: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getCreatinnineUrine Error", e);
      });
  };

  const getOsmolality = async () => {
    emitDataID('123');
    base
      .post(`/profiles/secure/get/123/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.osmolality;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Osmolality: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getOsmolality Error", e);
      });
  };

  const getCRP = async () => {
    emitDataID('124');
    base
      .post(`/profiles/secure/get/124/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.crp;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("CRP: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getCRP Error", e);
      });
  };

  const getInsulinFasting = async () => {
    emitDataID('125');
    base
      .post(`/profiles/secure/get/125/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.insulin_fasting;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Insulin Fasting: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getInsulinFasting Error", e);
      });
  };

  const getCortisol = async () => {
    emitDataID('126');
    base
      .post(`/profiles/secure/get/126/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.cortisol;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Cortisol: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getCortisol Error", e);
      });
  };

  const getTSH = async () => {
    emitDataID('127');
    base
      .post(`/profiles/secure/get/127/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.tsh;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("TSH: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getTSH Error", e);
      });
  };

  const getT4 = async () => {
    emitDataID('128');
    base
      .post(`/profiles/secure/get/128/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.t4;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("T4: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getT4 Error", e);
      });
  };

  const getT3 = async () => {
    emitDataID('129');
    base
      .post(`/profiles/secure/get/129/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.t3;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("T3: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getT3 Error", e);
      });
  };

  const getThyroglobulin = async () => {
    emitDataID('130');
    base
      .post(`/profiles/secure/get/130/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.thyroglobulin;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Thyroglobulin: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getThyroglobulin Error", e);
      });
  };

  const getThyroidAb = async () => {
    emitDataID('131');
    base
      .post(`/profiles/secure/get/131/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.thyroid_ab;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Thyroid Ab: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getThyroidAb Error", e);
      });
  };

  const getTestosterone = async () => {
    emitDataID('132');
    base
      .post(`/profiles/secure/get/132/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.testosterone;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Testosterone: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getTestosterone Error", e);
      });
  };

  const getOestrogenEstrogen = async () => {
    emitDataID('133');
    base
      .post(`/profiles/secure/get/133/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.oestrogen_estro;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Oestrogen Estrogen: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getOestrogenEstrogen Error", e);
      });
  };

  const getGHRandom = async () => {
    emitDataID('134');
    base
      .post(`/profiles/secure/get/134/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.gh_random;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("GH Random: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getGHRandom Error", e);
      });
  };

  const getPTH = async () => {
    emitDataID('135');
    base
      .post(`/profiles/secure/get/135/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.pth;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("PTH: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getPTH Error", e);
      });
  };

  const getBHCGQuantification = async () => {
    emitDataID('136');
    base
      .post(`/profiles/secure/get/136/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.b_hcg_quantific;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("BHCG Quantification: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getBHCGQuantification Error", e);
      });
  };

  const getFSHLH = async () => {
    emitDataID('137');
    base
      .post(`/profiles/secure/get/137/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.fsh_lh;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("FSHLH: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getFSHLH Error", e);
      });
  };

  const getCK = async () => {
    emitDataID('138');
    base
      .post(`/profiles/secure/get/138/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.ck;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("CK: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getCK Error", e);
      });
  };

  const getMyoglobin = async () => {
    emitDataID('139');
    base
      .post(`/profiles/secure/get/139/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.myoglobin;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Myoglobin: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getMyoglobin Error", e);
      });
  };

  const getCKMB = async () => {
    emitDataID('140');
    base
      .post(`/profiles/secure/get/140/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);
            const value = d.ck_mb;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("CKMB: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getCKMB Error", e);
      });
  };

  const getTroponins = async () => {
    emitDataID('141');
    base
      .post(`/profiles/secure/get/141/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.troponins;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Troponins: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getTroponins Error", e);
      });
  };

  const getTotalBilirubin = async () => {
    emitDataID('142');
    base
      .post(`/profiles/secure/get/142/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.total_bilirubin;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Total Bilirubin: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getTotalBilirubin Error", e);
      });
  };

  const getConjugatedBilirubin = async () => {
    emitDataID('143');
    base
      .post(`/profiles/secure/get/143/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.conjugated_bili;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Conjugated Bilirubin: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getConjugatedBilirubin Error", e);
      });
  };

  const getALT = async () => {
    emitDataID('144');
    base
      .post(`/profiles/secure/get/144/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.alt;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("ALT: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getALT Error", e);
      });
  };

  const getAST = async () => {
    emitDataID('145');
    base
      .post(`/profiles/secure/get/145/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.ast;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("AST: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getAST Error", e);
      });
  };

  const getALP = async () => {
    emitDataID('146');
    base
      .post(`/profiles/secure/get/146/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.alp;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("ALP: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getALP Error", e);
      });
  };

  const getGGT = async () => {
    emitDataID('147');
    base
      .post(`/profiles/secure/get/147/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.ggt;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("GGT: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getGGT Error", e);
      });
  };

  const getLDH = async () => {
    emitDataID('148');
    base
      .post(`/profiles/secure/get/148/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.ldh;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("LDH: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getLDH Error", e);
      });
  };

  const getImmunoglobulins = async () => {
    emitDataID('149');
    base
      .post(`/profiles/secure/get/149/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.immunoglobulins;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Immunoglobulins: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getImmunoglobulins Error", e);
      });
  };

  const getAmylaseSerum = async () => {
    emitDataID('150');
    base
      .post(`/profiles/secure/get/150/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.amylase_serum;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Amylase Serum: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getAmylaseSerum Error", e);
      });
  };

  const getLipaseSerum = async () => {
    emitDataID('151');
    base
      .post(`/profiles/secure/get/151/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.lipase_serum;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Lipase Serum: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getLipaseSerum Error", e);
      });
  };

  const getHb = async () => {
    emitDataID('152');
    base
      .post(`/profiles/secure/get/152/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.hb;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Hb: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getHb Error", e);
      });
  };

  const getMCV = async () => {
    emitDataID('153');
    base
      .post(`/profiles/secure/get/153/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.mcv;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("MCV: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getMCV Error", e);
      });
  };

  const getWCC = async () => {
    emitDataID('154');
    base
      .post(`/profiles/secure/get/154/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.wcc;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("WCC: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getWCC Error", e);
      });
  };

  const getPlatelet = async () => {
    emitDataID('155');
    base
      .post(`/profiles/secure/get/155/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.platelet;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Platelet: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getPlatelet Error", e);
      });
  };

  const getDifferentialCount = async () => {
    emitDataID('156');
    base
      .post(`/profiles/secure/get/156/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.differential_co;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Differential Count: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getDifferentialCount Error", e);
      });
  };

  const getSmear = async () => {
    emitDataID('157');
    base
      .post(`/profiles/secure/get/157/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.smear;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Smear: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getSmear Error", e);
      });
  };

  const getRetics = async () => {
    emitDataID('158');
    base
      .post(`/profiles/secure/get/158/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.retics;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Retics: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getRetics Error", e);
      });
  };

  const getINR = async () => {
    emitDataID('159');
    base
      .post(`/profiles/secure/get/159/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.inr;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("INR: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getINR Error", e);
      });
  };

  const getPTT = async () => {
    emitDataID('160');
    base
      .post(`/profiles/secure/get/160/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.ptt;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("PTT: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getPTT Error", e);
      });
  };

  const getDDimer = async () => {
    emitDataID('161');
    base
      .post(`/profiles/secure/get/161/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.d_dimer;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("D Dimer: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getDDimer Error", e);
      });
  };

  const getFibrinogen = async () => {
    emitDataID('162');
    base
      .post(`/profiles/secure/get/162/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.fibrinogen;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Fibrinogen: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getFibrinogen Error", e);
      });
  };

  const getESR = async () => {
    emitDataID('163');
    base
      .post(`/profiles/secure/get/163/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.esr;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("ESR: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getESR Error", e);
      });
  };

  const getAllergyTests = async () => {
    emitDataID('183');
    base
      .post(`/profiles/secure/get/183/?secure_pin=${props.securityCode}`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          dataList.value = response.data.details;

          categoriesList.value = [];
          dataSeriesList.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const value = d.allergy_tests;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(value));
          });

          generateDataSet("Allergy Tests: ", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          updateChart();
        }
      })
      .catch((e) => {
        console.error("getAllergyTests Error", e);
      });
  };

  onMounted(() => {
    nextTick(function () {
      ctx.value = document.getElementById("line-chart").getContext("2d");
      generateGraph(ctx.value, chartConfig.value);

      getCurrentDate();
      getCurrentYear();
      getTotalProtein();
    });
  });

</script>

<style>
#chart-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 2000px;
}

.chart {
  background-color: #efefef;
}

.custom-checkbox {
    --chkbg: var(--s);
    --chkfg: var(--sc);
    --tw-border-opacity: 1;
    border-color: hsl(var(--s) / var(--tw-border-opacity));
    flex-shrink: 0;;
    cursor: pointer;
    appearance: none;
    border-width: 1px;
    border-radius: var(--rounded-btn, 0.5rem /* 8px */);
}
.custom-checkbox:hover {
    --tw-border-opacity: 1;
    border-color: hsl(var(--s) / var(--tw-border-opacity));
}
.custom-checkbox:focus-visible {
    outline: 2px solid hsl(var(--s));
}
.custom-checkbox:checked, 
    .custom-checkbox[checked="true"] {
    --tw-border-opacity: 1;
    border-color: hsl(var(--s) / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: hsl(var(--s) / var(--tw-bg-opacity));
    --tw-text-opacity: 1;
    color: #005794;
}
</style>
