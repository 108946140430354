<template>
	<div ref="baseSelectRef" :key="modelValue">
		<div class="flex bg-grey-100 rounded-t-md">
			<div
				class="relative grow base-input transition-all duration-300 ease-in-out rounded-t-md"
				:class="{ 'shadow-md shadow-primary-400' : active}"
			>
				<label
					v-if="customPlaceholder"
					class="absolute pl-5 font-semibold text-[#035794] normal transition-all duration-300 ease-in-out hasPlaceholder"
					:class="{
						error: error ?? errorMessage,
						warning: warningMessages,
						success: successMessages,
					}"
				>
					<span>{{ label ?? "Base Select" }}</span>
				</label>
				<input
					:value="text"
					v-bind="$attrs"
					ref="selectInputRef"
					:name="name"
					class="select-element normal focus:outline-none rounded-t-md"
					:style="{height: computedHeight}"
					:disabled="!!disabled"
					@input="handleInput($event)"
					@keydown.down="handleArrowDown()"
					@keydown.up="handleArrowUp()"
					@keydown.enter="handleEnter($event)"
					@change="veeHandleChange($event)"
					@focus="handleFocus($event)"
					@blur="handleBlur"
					:readonly="readonly"
					:placeholder="customPlaceholder ?? label"
					:class="{
						error: errorMessage || error,
						warning: warningMessages,
						success: successMessages,
						selected: selectedIndex > -1,
						hasPlaceholderInput: customPlaceholder,
					}"
				/>
				<BaseLoading v-if="loading &&autocomplete" class="mt-0.5 mb-0" />

				<label
					:class="{
						error: error ?? errorMessage ,
						warning: warningMessages,
						success: successMessages,
					}"
					class="absolute pl-5 font-semibold text-[#035794] normal transition-all duration-300 ease-in-out z-50"
				>
					<span>{{ label ?? "Base Input" }}</span>
				</label>
				<div
					class="selection"
					tabindex="-1"
					v-show="!isNil(selectedItem) && !focused"
					@click="!disabled && $refs.selectInputRef.focus()"
					:key="text"
				>
					<div
						class="w-full h-full"
						:class="{'!text-gray-400 cursor-not-allowed': disabled}"
					>
						<slot name="selection" :item="selectedItem ?? {}" :disabled="selectedItem?.disabled">
							<span class="caret-black">{{ text }}</span>
						</slot>
					</div>
				</div>
				<span v-if="required" class="absolute top-0 right-1 text-red-600 font-bold">*</span>

				<ChevronDownIcon
					class="fill-darkprimary-500 w-6 h-6 absolute right-1 top-[27%] -translate-[27%]"
					:class="{'rotate-180 fill-[#0b7db6]': showItems}"
				/>

				<button
					v-if="clearable"
					v-show="!disabled && !readonly && text"
					v-tooltip="'Clear'"
					@click.prevent.stop="clear()"
					class="btn-circle btn-xs w-7 h-7 absolute right-8 top-[23%] -translate-[23%] hover:scale-110"
				>
					<XCircleIcon
						class="fill-darkprimary-500 w-7 h-7 hover:fill-darkprimary-700"
						:class="{'fill-[#0b7db6]': showItems}"
					/>
				</button>

				<ul
					v-if="showItems"
					class="items-container absolute bg-white w-full drop-shadow-lg rounded-b-md py-2 z-[1000] max-h-80 overflow-y-auto"
					:class="{
						'translate-y-7': (error ?? errorMessage) || warningMessages || successMessages,
					}"
					tabindex="0"
				>
					<li v-if="$slots['first-item']">
						<slot name="first-item"></slot>
					</li>
					<slot name="no-items" v-if="!filteredItems.length">
						<li class="px-3 py-2 text-center text-grey-200 italic">
							<span class="text-gray-400">No items to select from</span>
						</li>
					</slot>
					<li
						v-for="(fi, i) in filteredItems"
						:key="i"
						class="px-3 py-2 hover:bg-grey-100 hover:text-gray-500 cursor-pointer"
						@click.prevent.stop="!fi.disabled && itemSelect(fi)"
						:class="{selected: i === selectedIndex, disabled: fi.disabled}"
					>
						<slot
							name="item"
							:item="fi"
							:selected="i === selectedIndex"
							:disabled="fi.disabled"
						>{{ get(fi, itemText) }}</slot>
					</li>
				</ul>
			</div>
			<div v-if="$slots.append" class="min-w-[3rem] h-12 border-l-2 self-center">
				<slot name="append" />
			</div>
		</div>
		<div
			class="text-xs font-semibold my-1 px-2 messages"
			v-show="!noDetails || errorMessage || hint || error"
			:class="errorMessage || hint || error ? 'min-h-4' : 'h-4'"
		>
			<Transition name="fade" mode="out-in" v-if="errorMessage || error">
				<span class="text-red-500" v-if="error ?? errorMessage">{{ error ?? errorMessage }}</span>
				<span v-else-if="hint" class="text-grey-300">{{ hint }}</span>
			</Transition>
			<slot name="messages" v-else />
		</div>
	</div>
</template>

<script>
import { computed, nextTick, ref, toRef, watch } from "vue";
import { debounce, filter, find, findIndex, get, isNil } from "lodash";
import { useField } from "vee-validate";
import BaseLoading from "../BaseLoading.vue";
import { ChevronDownIcon, XCircleIcon } from '@heroicons/vue/24/solid';

export default {
	name: "BaseSelect",
	props: {
		modelValue: {},
		value: {},
		name: { type: String },
		items: { type: Array },
		itemText: { type: String },
		itemValue: { type: String },
		label: String,
		clearable: { type: Boolean },
		errorMessage: { type: String },
		warningMessages: { type: String },
		successMessages: { type: String },
		noDetails: Boolean,
		immediate: { type: Boolean },
		hint: String,
		refKey: { type: String, default: "" },
		returnObject: { type: Boolean },
		required: { type: Boolean },
		itemHeight: { type: [Number, String] },
		active: { type: Boolean },
		disabled: {},
		readonly: { type: Boolean },
		filterColumns: { type: Array },
		selection: { type: Function },
		customPlaceholder: { type: String },
		rules: {},
		loading: { type: Boolean, default: false },
		autocomplete: { type: Boolean, default: false },
		autocompleteMinLength: { type: Number, default: 0 },
		autocompleteDelay: { type: [Number, String], default: 500 },
	},
  components: {
    ChevronDownIcon,
    XCircleIcon,
    BaseLoading
	},
	emits: [
		"update:modelValue",
		"update:autoComplete",
		"input",
		"blur",
		"focus",
		"change",
		"keydown",
		"keyup",
		"keypress",
	],

	setup(props, { emit }) {
		const text = ref(null);
		const selectedIndex = ref(-1);
		const showItems = ref(false);
		const selectedValue = ref(props.modelValue ?? props.value ?? null);
		const focused = ref(false);
		const baseSelectRef = ref(null);
		const selectInputRef = ref(null);

		const computedHeight = computed(() => {
			if (!selectInputRef.value || !baseSelectRef.value) return "initial";
			let sEl = baseSelectRef.value.querySelector(".selection");

			let sStyle = getComputedStyle(sEl);
			let iStyle = getComputedStyle(selectInputRef.value);

			let sHeight = parseFloat(sStyle.height) || 0;
			let iHeight = parseFloat(iStyle.height) || 0;

			if (sHeight > iHeight) return sHeight + "px !important";
			else return "initial";
		});

		const selectedItem = computed(() => {
			return find(props.items, {
				[props.itemValue]: selectedValue.value,
			});
		});

		const filteredItems = computed(() => {
			const searchTextLower = text.value?.trim()?.toLowerCase();

			if (!searchTextLower) return props.items;
			return filter(props.items, (result) => {
				if (typeof result === "object") {
					const label = get(result, props.itemText);
					if (!label) return false;
					return label?.toLowerCase()?.includes(searchTextLower);
				}
			});
		});

		const {
			value: inputValue,
			errorMessage: error,
			handleBlur: veeHandleBlur,
			handleChange: veeHandleChange,
			resetField,
			meta,
		} = useField(toRef(props, "refKey"), props.rules ?? null, {
			initialValue: selectedValue.value,
			validateOnMount: props.immediate,
			validateOnValueUpdate: props.immediate || props.autocomplete,
		});

		const itemSelect = async (item, updateModelValue = true) => {
			if (typeof item === "object") {
				text.value = get(item, props.itemText);
			} else text.value = item;
			showItems.value = false;
			selectedValue.value = get(item, props.itemValue);
			selectedIndex.value = findIndex(props.items, {
				[props.itemValue]: selectedValue.value,
			});
			inputValue.value =
				props.autocomplete && text.value
					? text.value
					: selectedValue.value;
			if (updateModelValue) {
				if (props.returnObject) {
					emit("update:modelValue", item);
					emit("change", item);
				} else {
					emit("update:modelValue", selectedValue.value);
					emit("change", selectedValue.value);
				}
			}
		};

		// handle initial value if  modelValue or value is provided
		watch(
			() => [props.modelValue, props.value, props.items],
			(v, o) => {
				let x = v?.[0] ?? v?.[1] ?? null; // new value
				let y = o?.[0] ?? o?.[1] ?? null; // old value
				// avoid infinite loop
				if (x === y) return;
				if (isNil(x)) {
					// text.value = "";
					selectedIndex.value = -1;
					return;
				}
				let item = find(props.items, {
					[props.itemValue]: x,
				});
				if (item) itemSelect(item, false);
			},
			{ immediate: true, deep: true }
		);

		resetField();

		const debouncedInput = debounce((v) => {
			text.value = v?.trim();
		}, 500);

		const debouncedAutocomplete = debounce(
			(v) => {
				text.value = v?.trim();
				inputValue.value = text.value;
				selectedIndex.value = text.value;
				emit("update:autoComplete", v);
			},
			(props.autocompleteDelay ?? 0) * 1,
			{ trailing: true }
		);

		const handleInput = (ev) => {
			emit("input", ev?.target?.value);
			if (
				props.autocomplete &&
				ev.target.value.length >= props.autocompleteMinLength
			)
				debouncedAutocomplete(ev.target.value);
			else debouncedInput(ev.target.value);
		};

		const handleArrowDown = () => {
			if (selectedIndex.value < filteredItems.value.length - 1) {
				selectedIndex.value++;
			}
		};

		const handleArrowUp = () => {
			if (selectedIndex.value > 0) {
				selectedIndex.value--;
			}
		};

		const handleEnter = (event) => {
			if (selectedIndex.value !== -1)
				itemSelect(filteredItems.value[selectedIndex.value]);
			event.target.blur();
		};

		const scrollToItem = () => {
			const scrollContainer = document.querySelector(".items-container");
			const selectedElement = scrollContainer?.querySelector(".selected");
			if (selectedElement) {
				scrollContainer.scrollTop =
					selectedElement.offsetTop - scrollContainer.offsetTop;
			}
		};

		const handleFocus = async () => {
			if (!props.autocomplete) text.value = "";
			showItems.value = true;
			focused.value = true;
			await nextTick();
			scrollToItem();
			emit("focus", {
				target: selectInputRef.value,
				value: selectedValue.value,
			});
		};

		const handleBlur = (event) => {
			// if input has text, find the item with similar text as text value and select it
			// this is to handle auto complete when user types in the input
			if (!props.autocomplete) {
				let item = find(props.items, (i) => {
					if (typeof i === "object") {
						const label = get(i, props.itemText);
						if (!label) return false;
						return (
							label?.toLowerCase() === text.value?.toLowerCase()
						);
					}
				});
				if (item) itemSelect(item);
			}

			veeHandleBlur(event);
			focused.value = false;
			const cl = event?.relatedTarget?.classList;
			if (!event?.relatedTarget || !cl?.contains("items-container")) {
				showItems.value = false;
				if (!isNil(selectedValue.value))
					itemSelect(
						find(props.items, {
							[props.itemValue]: selectedValue.value,
						})
					);
			}
		};

		const clear = async () => {
			text.value = "";
			await nextTick();
			itemSelect(null);
		};

		const focus = () => {
			selectInputRef.value?.focus();
		};

		const select = () => {
			selectInputRef.value?.select();
		};

		return {
			get,
			isNil,
			text,
			selectedIndex,
			filteredItems,
			itemSelect,
			handleInput,
			handleArrowDown,
			handleArrowUp,
			handleEnter,
			handleFocus,
			showItems,
			error,
			handleBlur,
			meta,
			veeHandleChange,
			focused,
			selectedItem,
			selectedValue,
			selectInputRef,
			baseSelectRef,
			computedHeight,
			clear,
			focus,
			select,
		};
	},
};
</script>

<style lang="postcss" scoped >
.items-container .selected {
	@apply bg-[#0b7db6] text-white;
}

.disabled {
	@apply !text-gray-400 cursor-not-allowed;
}
.messages {
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
}
label.normal {
	@apply transition duration-300 ease-in-out;
}
.base-input:focus-within > label.normal:not(.error, .warning, .success) {
	@apply text-[#0b7db6];
}
label.error {
	@apply text-red-500;
}
label.warning {
	@apply text-red-500;
}
label.success {
  @apply text-green-500;
}

label {
	top: 50%;
}
.select-element:not(:placeholder-shown) ~ label {
	@apply text-xs;
	top: 2px;
}

.select-element:not(:placeholder-shown) {
	@apply pb-1 pt-5;
}

.select-element:focus ~ label {
	@apply text-xs;
	top: 2px;
}

.select-element:focus::placeholder {
	opacity: 0;
}

.select-element:placeholder-shown:not(.select-element:focus) ~ label {
	@apply hidden;
}

.select-element {
	@apply block bg-grey-100 w-full pl-5 py-3 border-b-2 border-grey-200 font-normal text-black outline outline-2 outline-gray-300 transition-all duration-200 ease-in-out text-sm;
  background-color: #fff;
}
.select-element:disabled {
	@apply !text-gray-400;
}
.select-element.normal {
	@apply focus:border-[#0b7db6];
}
.select-element.error {
	@apply border-red-500;
}
.select-element.warning {
	@apply border-amber-500;
}
.select-element.success {
  @apply border-green-500;
}

.select-element:focus {
	@apply pb-1 pt-5 outline outline-2 outline-[#0b7db6];
}

::placeholder {
	@apply text-[#686663] font-semibold;
}
label.hasPlaceholder{
	@apply !text-xs;
	top: 2px !important;
}

.hasPlaceholderInput::placeholder{
	@apply text-darkprimary-100 italic absolute;
	top:48%;
}

.select-element:focus ~ .selection, .select-element.selected ~ .selection  {
	@apply pb-1 pt-5;
}

.selection {
	@apply absolute top-0  font-normal text-black outline-0
	transition-all duration-200 ease-in-out text-sm w-full overflow-y-hidden z-[0] px-5 cursor-text
}

.select-element.selected {
	@apply pb-1 !pt-5;
  background-color: #fff;
}

.rounded-t-md {
  border-radius: 0.375rem;
}
</style>
