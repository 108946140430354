<template>
  <!-- Dates -->
  <div class="grid grid-cols-2 gap-5 mb-8 font-paragraph-500">
    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-4 gap-2">
        <h1 class="headingOne text-base self-center font-normal col-span-2">
          Select Date Range
        </h1>
        <button
          class="secondaryBtn text-xs focus:bg-[#035795] focus:border-[#035795] active:bg-[#035795]"
          @click="getCurrentMonth()"
        >
          This Month
        </button>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="getCurrentYear()"
        >
          This Year
        </button>
      </div>
    </div>

    <div class="bg-white rounded p-5">
      <div class="grid grid-cols-3 gap-2">
        <base-date-time-picker
          v-model="fromDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <base-date-time-picker
          v-model="toDate"
          :maxDate="new Date()"
        ></base-date-time-picker>
        <button
          class="primaryBtn text-xs focus:bg-[#035795] focus:border-[#035795]"
          @click="customizeDateRange()"
        >
        Customize Date Range
        </button>
      </div>
    </div>
  </div>

  <!-- Tabs -->
  <div class="grid grid-cols-1 gap-2 mb-4">
    <button class="btnTab" @click="getPainScore()">
      Pain Score
      Data
    </button>
  </div>

  <!-- Chart -->
  <div class="relative bg-gray-300 rounded-lg rounded-t-none p-10 mb-5" id="chartWrapper">
    <canvas id="line-chart"></canvas>
  </div>
</template>

<script setup>
  import { ref, onMounted, computed, nextTick } from "vue";
  import base from "../../services/api";
  import { formatISO } from "date-fns";
  import moment from "moment";
  import { Chart, registerables } from "chart.js";

  import BaseDateTimePicker from "../../../src/assets/Form/BaseDateTimePicker.vue";

  Chart.register(...registerables);

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD HH:mm"); // Consistent date formatting
  };

  const fromDate = ref("");
  const toDate = ref("");

  const graphData = {};
  const dataList = ref([]);
  const categoriesList = ref(null);
  const dataSeriesList = ref(null);
  const dataSeriesList2 = ref(null);
  const dataSeriesList3 = ref(null);

  const datasets = ref(null);

  const ctx = ref(null);

  const painScoreTab = ref(true);

  const chartConfig = computed(() => { return {
    type: "line", // Define the type of chart
    data: {
      labels: categoriesList.value, // Use dynamic list of labels (dates)
      datasets: datasets.value, // Dynamic datasets
    },
    options: {
      maintainAspectRatio: true,
      responsive: true,
      scales: {
        x: [{
          type: 'time',
          time: {
            unit: 'second',
            displayFormats: {
              second: 'YYYY-MM-DD HH:mm'
            }
          },
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        }],
        y: {
          grid: {
            drawBorder: false,
            display: true,
            color: "#ffffff",
          },
          ticks: {
            fontColor: "white",
          },
        },
      },
      title: {
        display: false,
      },
      legend: {
        labels: {
          fontColor: "white",
        },
        align: "end",
        position: "bottom",
      },
      tooltips: {
        mode: "index", // Tooltip mode
        intersect: false,
      },
      hover: {
        mode: "nearest", // Hover behavior
        intersect: true,
      },
    },
  }});

  let myLine = null;

  const generateGraph = async (ctx, chartConfig) => {
    myLine = new Chart(ctx, chartConfig);
  }

  const getCurrentDate = async () => {
    const currentDate = new Date();
    toDate.value = formatISO(currentDate);
  }

  const getCurrentYear = async () => {
    const firstDayYear = new Date(new Date().getFullYear(), 0, 1);
      fromDate.value = formatISO(firstDayYear);
      refreshTabs();
  };

  const customizeDateRange = async () => {
      refreshTabs();
  };

  const getCurrentMonth = async () => {
    const firstDayMonth = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        1
      );

      fromDate.value = formatISO(firstDayMonth);
      refreshTabs();
  };

  const generateDataSet = async (label,
    data,
    backgroundColor = "#F04248",
    borderColor = "#F04248") => {
      graphData.value = {
          label: label,
          backgroundColor: backgroundColor,
          borderColor: borderColor,
          data: data,
          fill: false
        }
  };

  const updateChart = async () => {
    myLine.data.labels = categoriesList.value;
    myLine.data.datasets = datasets.value;
    myLine.update();
  };

  const refreshTabs = async () => {

    /*
      This function is reponsible for refreshing graphs when a user adds data or selects date ranges
      This is to ensure that data can instantly retrieved without the user needing to refresh the page everytime
    */

    if(painScoreTab.value == true){
      getPainScore();
    }
  };

  const getPainScore = async () => {
    base
      .post(`/v2/research/get-pain-score/`, {
        date_from: fromDate.value,
        date_to: toDate.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {

          dataList.value = response.data.data;
          categoriesList.value = [];
          dataSeriesList.value = [];
          dataSeriesList2.value = [];
          dataSeriesList3.value = [];
          datasets.value = [];

          dataList.value.forEach((d) => {
            const date = formatDate(d.created_on);

            const { pain_at_rest, pain_with_motion, difficulty_of_movement } = d;

            categoriesList.value.push(date);
            dataSeriesList.value.push(parseInt(pain_at_rest));
            dataSeriesList2.value.push(parseInt(pain_with_motion));
            dataSeriesList3.value.push(parseInt(difficulty_of_movement));
          });

          generateDataSet("Pain at Rest (0 - 10)", dataSeriesList);

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Pain With Motion (0 - 10)",
            dataSeriesList2,
            "#058EF0",
            "#058EF0"
          );

          datasets.value.push(
            graphData.value
          );

          generateDataSet(
            "Movement Difficulty (0 - 10)",
            dataSeriesList3,
            "#FFD447",
            "#FFD447"
          );

          datasets.value.push(
            graphData.value
          );
          updateChart();
        }
      })
      .catch((e) => {
        console.error("getPainScore Error", e);
      });
  };

  onMounted(() => {
    nextTick(function () {
      ctx.value = document.getElementById("line-chart").getContext("2d");
      generateGraph(ctx.value, chartConfig.value);

      getCurrentDate();
      getCurrentYear();
      getPainScore();
    });
  });

</script>
<style>
#chart-wrapper {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 2000px;
}
</style>
