<template>
  <div class="grid grid-cols-5 h-screen">
    <!-- div left -->
    <div class="relative col-span-2">
      <!-- <img
        class="absolute opacity-60 mt-80 rotate-90"
        src="/images/assic-logo-white.png"
        alt="Assic Heart monitor"
      />-->
      <img
        class="h-full object-cover hero-image"
        src="/images/assic-home-blue.png"
        alt="Assic Heart monitor"
      />
    </div>

    <!-- div right -->
    <div class="flex w-full h-full justify-center bg-gray-100 col-span-3 pt-24">
      <div class="self-center w-4/5">
        <div class="text-center">
          <img class="mx-auto w-2/5 xl:w-2/5" src="/images/assic-login-logo.png" alt="Assic Logo" />
          <h1 class="text-2xl mt-5 font-bold text-primary-500">REGISTER</h1>
        </div>

        <div class="grid gap-y-6 py-5">
          <hr class="col-span-4 self-center border-t-2 border-grey-200 mx-8 mb-3" />
        </div>

        <Form as="div" v-slot="{ handleSubmit }"  ref="registerForm" class="mx-8">
          <div class="bg-[#F8F8F8] shadow-md rounded p-5">
            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.email"
                refKey="form.user.email"
                :rules="emailRules1"
                required
                label="Email Address"
              ></base-input>
              <div class="error text-xs text-secondary-700" v-if="errors.email">{{ errors.email }}</div>
            </div>
            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.first_name"
                refKey="form.user.first_name"
                :rules="requiredRules"
                required
                label="First Name"
              ></base-input>
            </div>

            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.last_name"
                refKey="form.user.last_name"
                :rules="requiredRules"
                required
                label="Last Name"
              ></base-input>
            </div>

            <div class="form-control w-full mb-3">
              <base-select
								v-model="form.user.gender"
                refKey="form.user.gender"
								label="Gender"
                required
								:items="[{ text: 'Male' }, { text: 'Female' }]"
								item-text="text"
								item-value="text"
							></base-select>
            </div>

            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.height"
                refKey="form.user.height"
                :rules="requiredRules"
                label="Height"
                required
                v-mask="'#.##'"
              ></base-input>
            </div>

            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.mass"
                refKey="form.user.mass"
                :rules="requiredRules"
                label="Mass"
                required
                type="number"
              ></base-input>
            </div>

            <div class="form-control w-full mb-3">
              <base-date-picker
                v-model="form.user.date_of_birth"
                refKey="form.user.date_of_birth"
                noDetails
                label="Date of Birth"
                :maxDate="new Date()"
                required
              ></base-date-picker>
            </div>
            <div class="form-control w-full mb-3">
              <base-select
								v-model="form.user.fitness_type"
                refKey="form.user.fitness_type"
								label="Fitness Type"
                :rules="requiredRules"
                :items="[{ text: 'Sedentary', value: 'sedentary' }, { text: 'Athlete', value: 'athlete' }]"
								item-text="text"
								item-value="value"
							></base-select>
            </div>

            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.password"
                :rules="passwordRules"
                refKey="form.user.password"
                required
                autocomplete="new-password"
                noDetails
                label="New Password"
                :type="passwordVisible ? 'text' : 'password'"
              >
                <template v-slot:append>
                  <button
                    class="btn btn-square btn-outline mt-1 border-none rounded-none hover:btn-ghost"
                    @click.prevent="passwordVisible = !passwordVisible"
                    type="button"
                  >
                    <EyeIcon v-if="passwordVisible" class="w-6 h6" />
                    <EyeSlashIcon v-else class="w-6 h6" />
                  </button>
                </template>
              </base-input>
            </div>

            <div class="form-control w-full mb-3">
              <base-input
                v-model="form.user.confirmPassword"
                :rules="passwordRules2"
                refKey="form.user.confirmPassword"
                required
                autocomplete="new-password"
                noDetails
                label="Confirm Password"
                :type="passwordConfirmVisible ? 'text' : 'password'"
              >
                <template v-slot:append>
                  <button
                    class="btn btn-square btn-outline mt-1 border-none rounded-none hover:btn-ghost"
                    @click.prevent="passwordConfirmVisible = !passwordConfirmVisible"
                    type="button"
                  ><EyeIcon v-if="passwordConfirmVisible" class="w-6 h6" />
                    <EyeSlashIcon v-else class="w-6 h6" />
                  </button>
                </template>
              </base-input>
              <div
                class="error text-xs text-secondary-700"
                v-if="errors.password"
              >{{ errors.password }}</div>
            </div>
          </div>

          <div class="grid gap-y-6 mt-6">
            <button
              class="primaryBtn w-40 mx-auto disabled:bg-slate-300 disabled:btn-active text-white"
              :disabled="!register"
              @click="handleSubmit(registerUser())"
            >
              REGISTER
            </button>
            <hr class="border-1 border-grey-200" />
            <div class="grid justify-center mb-5">
              <router-link
                to="/"
                class="text-secondary-500 font-semibold text-base"
              >Already have an account? Log in</router-link>
            </div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, reactive, watch, inject } from 'vue';
import { string } from "yup";

import BaseInput from "../../src/assets/Form/BaseInput.vue";
import BaseDatePicker from "../../src/assets/Form/BaseDatePicker.vue";
import BaseSelect from "@/assets/Form/BaseSelect.vue";
import { EyeSlashIcon, EyeIcon } from '@heroicons/vue/24/solid';
import { Form } from "vee-validate";
import { toast } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

export default {
  components: {
    BaseDatePicker,
    BaseInput,
    BaseSelect,
    EyeSlashIcon,
    EyeIcon,
    Form,
  },

  name: "RegisterView",

  setup() {

    let initialForm = {
			user: {},
		};

    const store = useStore();
		const router = useRouter();

    const form = reactive(initialForm);
    const registerForm = ref(null);
    const API = inject("axios");

    const password = ref();
    const confirmPassword = ref();

    const email = ref();
    const first_name = ref();
    const last_name = ref();
    const gender = ref();
    const height = ref();
    const mass = ref();
    const fitness_type = ref();
    const errors = ref([]);
    const passwordVisible = ref(false);
    const passwordConfirmVisible = ref(false);
    const date_of_birth = ref();

    const register = ref(false);

    const requiredRules = string().required("This field is required");

		const emailRules1 = requiredRules.email("Email address not valid");

		const passwordRules = requiredRules
			.min(6, "Password should be at least 6 characters");

		const passwordRules2 = requiredRules.test(
			"password",
			"Passwords do not match",
			(value) => {
				return value === form.user.password;
			}
		);

    const registerUser = async () => {
			const isValid = await registerForm.value.validate();
			if (isValid.valid) {
        API.post("/v2/user/create-user/", {
          email: form.user.email,
          first_name: form.user.first_name,
          last_name: form.user.last_name,
          gender: form.user.gender,
          height: form.user.height,
          mass: form.user.mass,
          date_of_birth: form.user.date_of_birth,
          fitness_type: form.user.fitness_type,
          password: form.user.password,
        })
        .then((res) => {
          if (res.data?.status === "success") {
            toast('Sucessfully Registered User!', {
              type: 'success',
              position: toast.POSITION.BOTTOM_RIGHT,
            });
            API.post("/user/login-user/", {
                email: form.user.email,
                password: form.user.password,
              })
              .then((response) => {
                if (response.data?.status === "success") {
                  store.dispatch("auth/setUser", response.data.details);
                  router.push({ path: "/dashboard" });
                }
              })
              .catch((e) => {
                console.error("Login Error", e);
                toast('Unable To Login!', {
                  type: 'error',
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              });
          }
        })
        .catch((e) => {
          console.error("Register Error", e);
          toast('Unable To Register!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        });

			} else {
        toast('Please fill in all required fields!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
        });
			}
		};

    watch(form, () => {
      if((form.user.confirmPassword) && (form.user.confirmPassword === form.user.password)){
        register.value = true;
      } else {
        register.value = false;
      }
    })

    return {
      date_of_birth,
      passwordVisible,
      emailRules1,
      passwordRules,
      passwordRules2,
      password,
      confirmPassword,
      email,
      first_name,
      last_name,
      gender,
      height,
      mass,
      fitness_type,
      errors,
      register,
      requiredRules,
      form,
      passwordConfirmVisible,
      registerUser,
      registerForm
    }
  },

  methods: {
    ...mapActions({
      setUser: "auth/setUser",
    }),
  },
};
</script>

<style scoped>
</style>
