<template>
  <HeaderShape></HeaderShape>

  <ConfirmationModal v-if="noSecurePin === false">
    <template v-slot:header>No Security Pin</template>
    <template v-slot:content>
      You don't currently have a secure pin, please go to the profile section to generate one
    </template>
    <template v-slot:footer>
      <div class="flex justify-around">
        <router-link 
          class="w-full" 
          to="/profile"
        >
          <button class="primaryBtn w-40">Profile</button>
        </router-link>
      </div>
    </template>
    <template v-slot:background>
      <div
        v-if="access === false"
        class="opacity-75 fixed inset-0 z-40 bg-black"
      ></div>
    </template>
  </ConfirmationModal>

  <ConfirmationModal v-if="access === false">
    <template v-slot:header>Enter Security Pin</template>
    <template v-slot:content>
      <div class="form-control w-full mb-3">
        <base-input
          v-model="access_security_pin"
          refKey="access_security_pin"
          required
          noDetails
          label="Security Pin"
          :type="pinVisible ? 'text' : 'password'"
        >
          <template v-slot:append>
            <button
              class="btn btn-square btn-outline mt-1 border-none rounded-none hover:btn-ghost"
              @click.prevent="pinVisible = !pinVisible"
              type="button"
            >
              <EyeIcon v-if="pinVisible" class="w-6 h6" />
              <EyeSlashIcon v-else class="w-6 h6" />
            </button>
          </template>
        </base-input>
      </div>
    </template>

    <template v-slot:footer>
      <div class="flex justify-around">
        <button
          class="primaryBtn w-40"
          @click="
            accessData();
          "
        >
          Enter
        </button>
      </div>
    </template>
    <template v-slot:background>
      <div
        v-if="access === false"
        class="opacity-75 fixed inset-0 z-40 bg-black"
      ></div>
    </template>
  </ConfirmationModal>

  <section class="sectionView -mt-5 text-paragraph-500">
    <div class="container mx-auto px-5">
      <!-- HEADER -->
      <div class="headerDiv">
        <base-card class="col-span-4 bg-white grid grid-cols-6">
          <img
            class="h-28 w-28 self-center"
            src="https://assic-files.ams3.digitaloceanspaces.com/PF-profiles/activity.png"
          />
          <div class="col-span-5 justify-center self-center">
            <p class="headingOne">Medical Profile</p>
            <p class="mt-3">View Medical Profile.</p>
          </div>
        </base-card>
        <base-card class="col-span-2 mx-auto grid grid-cols-2 content-center">
          <p class="headingOne text-lg text-gray-900 font-bold self-center">
            Add Medical Data
          </p>

          <div>
            <button
              @click="openModal()"
              class="flex primaryBtn h-12 w-12 float-right mx-auto rounded-full self-center"
            >
              <span class="text-4xl self-center -mt-2">+</span>
            </button>
          </div>
        </base-card>
      </div>
      <!-- CHARTS -->
      <div v-if="!loading">
        <MedicalCharts :key="medicalChartKey" :security-code="access_security_pin" @retrieve-tab-id="identifyTab"></MedicalCharts>
      </div>
    </div>
  </section>

  <!-- MODAL -->
  <AddModal v-if="modalOpen">
    <template v-slot:header>Medical Data</template>
    <template v-slot:content>
      <div class="grid grid-cols-3 mt-5 gap-5">
      <div>
        <base-select
            v-model="selectedOption"
            refKey="id"
            label="Select Data Type"
            :items="myOptions"
            item-text="name"
            item-value="id"
          ></base-select>
      </div>
      <div v-if="myForm" class="col-span-2 modalForm">
        <h2 class="headingTwo mb-5"><strong>Enter Data Values</strong></h2>
        <base-date-picker
          v-model="selectedDate"
          label="Pick a Date"
          :maxDate="new Date()"
          :rules="requiredRules"
          required
        ></base-date-picker>
        <template v-for="(fi, j) in myForm" :key="j">
          <div
            v-if="fi.value_type == 'integer'"
            class="form-control w-full"
          >
            <base-input
              v-model="submitForm[fi.name]"
              refKey="fi.name"
              :label="fi.ui_name"
              :additionalLabel="true"
              :additionalLabelValue="fi.unit"
              :rules="requiredRules"
              required
              type="number"
            ></base-input>
          </div>
          <div v-else-if="fi.value_type == 'date'" class="form-control w-full">
            <base-date-picker
              v-model="submitForm[fi.name]"
              refKey="fi.name"
              :label="fi.ui_name"
               :additionalLabel="true"
              :additionalLabelValue="fi.unit"
              :maxDate="new Date()"
              :rules="requiredRules"
              required
            ></base-date-picker>
          </div>
          <div v-else-if="fi.value_type == 'boolean'" class="form-control w-full">
            <base-select
            v-model="submitForm[fi.name]"
            refKey="fi.name"
            :label="fi.ui_name"
            :additionalLabel="true"
            :additionalLabelValue="fi.unit"
            :items="[{ text: 'Yes', value: '1' }, { text: 'No', value: '0' }]"
            item-text="text"
            item-value="value"
            :rules="requiredRules"
            required
          ></base-select>
          </div>
          <div v-else class="form-control w-full">
            <base-input
              v-model="submitForm[fi.name]"
              refKey="fi.name"
              :label="fi.ui_name"
              :additionalLabel="true"
              :additionalLabelValue="fi.unit"
              :rules="requiredRules"
              required
            ></base-input>
          </div>

        </template>
        <div>
          <base-input
            v-model="data_security_pin"
            refKey="data_security_pin"
            :rules="requiredRules"
            required
            noDetails
            label="Security Pin"
            :type="pinVisible ? 'text' : 'password'"
          >
            <template v-slot:append>
              <button
                class="btn btn-square btn-outline mt-1 border-none rounded-none hover:btn-ghost"
                @click.prevent="pinVisible = !pinVisible"
                type="button"
              >
                <EyeIcon v-if="pinVisible" class="w-6 h6" />
                <EyeSlashIcon v-else class="w-6 h6" />
              </button>
            </template>
          </base-input>
        </div>
        <div class="flex justify-between">
          <div></div>
          <button class="primaryBtn w-40 mt-5" @click="addData()">Add Data</button>
        </div>

      </div>
    </div>
    </template>
    <template v-slot:footer>
      <button class="secondaryBtn w-40" @click="closeModal">Close</button>
    </template>
    <template v-slot:background>
      <div
        v-if="modalOpen"
        class="opacity-75 fixed inset-0 z-40 bg-black"
      ></div>
    </template>
  </AddModal>
</template>

<script setup>
 // import axios from "axios";
  import MedicalCharts from "../components/charts/MedicalCharts.vue";
  import BaseCard from "../assets/BaseCard.vue";
  import HeaderShape from "../assets/HeaderShape.vue";
  import { ref, onMounted, computed, watch } from "vue";
  import { useStore } from "vuex";
  import AddModal from "../assets/AddModal.vue";
  import ConfirmationModal from "../assets/ConfirmationModal.vue";
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';
  import base from "../services/api";
  import { map, find } from "lodash";
  import BaseInput from "../../src/assets/Form/BaseInput.vue";
  import BaseDatePicker from "../../src/assets/Form/BaseDatePicker.vue";
  import BaseSelect from "@/assets/Form/BaseSelect.vue";
  import { EyeSlashIcon, EyeIcon } from '@heroicons/vue/24/solid';
  import { formatISO } from "date-fns";
  import { string } from "yup";

  const access = ref(true);
  const noSecurePin = ref(true);
  const loading = ref(true);
  const modalOpen = ref(false); // Modal state
  const access_security_pin = ref(""); // Password for re-authentication
  const data_security_pin = ref("");
  const pinVisible = ref(false);

  //const date = ref();
  const tab_id = ref();
  const selectedDate = ref();
  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]);

  let startOfTheYear = new Date(new Date().getFullYear(), 0, 1);
  let currentDate = new Date();

  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `ASSIC ${user.value.token}`,
  };

  const requiredRules = string().required("This field is required");

  //const emailRules1 = requiredRules.email("Email address not valid");


  // Modal functions
  function openModal() {
    modalOpen.value = true;
  }

  const verifySecurePin = async() => {
    base.post('/v2/user/verify-secure-pin/')
    .then((response) => {
      if (response.data.data == 'User has no secure pin') {
        access.value = true
        noSecurePin.value = false
      } else {
        access.value = false
      }
      loading.value = false
    })
  }

  const identifyTab = async (id) => {
    if(access_security_pin.value != ""){
      access.value = true;
    } else {
      access.value = false;
    }
    tab_id.value = id;
  };

  function closeModal() {
    modalOpen.value = false;
  }

  const medicalChartKey = ref(0);
  // Refreshes the entirey of all tabs for the charts componenet
  const forceRerender = () => {
    medicalChartKey.value += 1;
  }

  const myProfile = computed(() => store.getters['medicalProfile']);
  const myOptions = computed(() => map(myProfile.value?.dataset_set, ({ name, id }) => ({ name, id }))) ?? [];
  const selectedOption = ref(null);
  const submitForm = ref({});


  const myForm = computed(() => find(myProfile.value?.dataset_set, { id: selectedOption.value * 1 })?.structure?.elements);

  watch(selectedOption, () => {
    submitForm.value = {}
  });

  const accessData = async () => {
    base
      .post(
        `/v2/profiles/secure/get/${tab_id.value}?secure_pin=${access_security_pin.value}`,
        {
          date_from: formatISO(startOfTheYear),
          date_to: formatISO(currentDate),
        }, { headers })
        .then((response) => {
        if (response.data?.status === "success") {
          toast('Laboratory Data Successfully Added!', {
              type: 'success',
              position: toast.POSITION.BOTTOM_RIGHT,
          });

          access.value = true;
        } else if(response.data?.message === "No data available"){
            toast('No data availible!', {
                type: 'warning',
                position: toast.POSITION.BOTTOM_RIGHT,
            });

            access.value = true;
        }
      })
  }

  const addData = async () => {

    const postAPI = find(myProfile.value?.dataset_set, { id: selectedOption.value * 1 })?.post_api_name;

    const apiName = "/v2/profiles/";
    base
      .post(`${apiName}${postAPI}/?secure_pin=${data_security_pin.value}`,
        {
          ...submitForm.value,
          date: selectedDate.value
        }, { headers })
        .then((response) => {
          if (response.data?.status === "success") {
            toast('Medical Data Successfully Added!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            data_security_pin.value = "";
            closeModal();
            forceRerender();
          } else {
            toast(response.data?.message, {
                type: 'error',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
          }
        })
        .catch((e) => {
          console.error("Add Medical Data Error", e);
          toast('Unable To Add Medical Data!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }

  // Check if re-authentication is needed on component mount
  onMounted(() => {
    verifySecurePin()
  });

  /*const parseDateFormat = async (dateString) => {
    date.value = new Date(dateString);
    console.log('parseDateFormat');
    console.log(date.value);
  };*/
</script>
