<template>
  <HeaderShape></HeaderShape>

  <section class="relative pt-5 pb-12 bg-[#F8F8F8]">
    <div class="container mx-auto lg:px-5 xl:px-20">
      <!-- HEADER -->
      <div class="headerDiv mb-5">
        <base-card class="p-0">
          <img src="../../public/images/pf.png" alt class="rounded w-32 h-32 mx-auto" />
        </base-card>
        <base-card class="col-span-3 flex bg-white">
          <div class="grid grid-cols-4 gap-2 content-center">
            <h1 class="headingOne self-center font-normal col-span-4">
              My
              <span class="font-bold">Workout</span> Routines
            </h1>
          </div>
        </base-card>

        <base-card class="grid grid-cols-2 col-span-2">
          <p class="self-center">
            <span class="font-bold">Add</span> Routines
          </p>
          <div class="grid grid-cols-1 content-center gap-2 text-xs self-center">
            <button
              @click="addFrom = 'list'"
              class="primaryBtn self-center px-0 uppercase text-xs"
            >Add Routines From List</button>
            <button
              @click="addFrom = 'custom'; custom = 'lists'"
              class="secondaryBtn self-center px-0 uppercase text-xs"
            >Add Custom Routines</button>
          </div>
        </base-card>
      </div>

      <div class="bg-white rounded p-5 mb-6 shadow-xl">
        <!-- YOUR ROUTINES -->
        <div class="p-5 mx-auto">
          <p class="headingOne text-2xl font-bold text-[#0b7db6] mb-3">Your Routines</p>

          <div class="bg-white rounded grid grid-cols-1 gap-10">
            <div
              v-for="selectedRoutine in selectedRoutinesList"
              :key="selectedRoutine.id"
            >
              <div class="bg-[#F8F8F8] p-5 rounded shadow-md">
                <div class="flex w-full pb-3">
                  <div class="headingOne font-bold text-lg self-center">{{ selectedRoutine.name }}</div>

                  <div class="bg-secondary-500 text-white rounded-full mx-5 px-2 py-0.5 text-xs self-center">
                    {{
                      selectedRoutine.category
                    }}
                  </div>
                  <div class="flex-auto"></div>

                  <div>
                    <button
                      @click="confirmRoutineDeletionModal(selectedRoutine.id);"
                      class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
                    >
                      Delete
                      <img
                        class="h-5 w-5 ml-2"
                        src="../assets/img/Trash.svg"
                      />
                    </button>
                    <button
                      @click="editRoutine(selectedRoutine)"
                      class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
                    >
                      Edit
                      <img
                        class="h-5 w-5 ml-2"
                        src="../assets/img/Edit.svg"
                      />
                    </button>
                    <button class="primaryBtn uppercase text-xs mx-1 border-none  px-2">
                      Add Reminder
                      <img
                        class="h-5 w-5 ml-2"
                        src="../assets/img/Reminder.svg"
                      />
                    </button>
                    <button
                      v-if="activeInfo[selectedRoutine.id] == false"
                      @click="expand(selectedRoutine.id), toggleInfo(selectedRoutine.id)"
                      class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
                    >
                      More
                      <img
                        class="h-5 w-5 ml-2 secondaryBtnIcon"
                        src="../assets/img/More.svg"
                      />
                    </button>
                    <button
                      v-else-if="activeInfo[selectedRoutine.id] == true"
                      @click="expand(selectedRoutine.id), toggleInfo(selectedRoutine.id)"
                      class="primaryBtn uppercase text-xs mx-1 border-none  px-2"
                    >
                      Hide
                      <img
                        class="h-5 w-5 ml-2 secondaryBtnIcon"
                        src="../assets/img/Hide.svg"
                      />
                    </button>
                    <button
                      class="tertiaryBtn uppercase border-none text-xs mx-1 px-2"
                      @click="startRoutine(selectedRoutine); startRoutineTimer(selectedRoutine)"
                    >
                      Start Routine
                      <img
                        class="h-4 w-4 ml-2"
                        src="../assets/img/StartButton.svg"
                      />
                    </button>
                  </div>
                </div>
                <div
                  v-if="expand === false"
                  class="grid grid-cols-3 gap-5"
                >
                  <div class="py-2 my-2 bg-white rounded">
                    <div class="text-xs text-center text-[#0b7db6] uppercase">
                      {{
                        selectedRoutine.routineexercise_set[0].exercise.name
                      }}
                    </div>
                    <img
                      :src="selectedRoutine.routineexercise_set[0].exercise.animation"
                      class="my-3 mx-auto"
                    />
                    <div class="w-1/2 mx-auto grid grid-cols-2 text-xs">
                      <p class="self-center text-gray-500">Reps:</p>
                      <p class="text-center px-2">{{ selectedRoutine.routineexercise_set[0].reps }}</p>
                    </div>
                  </div>
                  <div class="py-2 my-2 bg-white rounded">
                    <div class="text-xs text-center text-[#0b7db6] uppercase">
                      {{
                        selectedRoutine.routineexercise_set[1].exercise.name
                      }}
                    </div>
                    <img
                      :src="selectedRoutine.routineexercise_set[1].exercise.animation"
                      class="my-3 mx-auto"
                    />
                    <div class="w-1/2 mx-auto grid grid-cols-2 text-xs">
                      <p class="self-center text-gray-500">Reps:</p>
                      <p class="text-center px-2">{{ selectedRoutine.routineexercise_set[1].reps }}</p>
                    </div>
                  </div>
                  <div class="py-2 my-2 bg-white rounded">
                    <div class="text-xs text-center text-[#0b7db6] uppercase">
                      {{
                        selectedRoutine.routineexercise_set[2].exercise.name
                      }}
                    </div>
                    <img
                      :src="selectedRoutine.routineexercise_set[2].exercise.animation"
                      class="my-3 mx-auto"
                    />
                    <div class="w-1/2 mx-auto grid grid-cols-2 text-xs">
                      <p class="self-center text-gray-500">Reps:</p>
                      <p class="text-center px-2">{{ selectedRoutine.routineexercise_set[2].reps }}</p>
                    </div>
                  </div>
                </div>

                <div
                  :id="selectedRoutine.id"
                  style="display: none"
                >
                  <div class="grid grid-cols-1 gap-5" v-if="selectedRoutine.description != '' || selectedRoutine.safety_info != ''">
                    <div class="bg-white rounded p-5 grid grid-cols-1 lg:grid-cols-4 gap-5 my-2 border-b">
                      <div v-if="selectedRoutine.youtube_url !== ''" class="col-span-4" style="text-align: -webkit-center;">
                        <iframe
                          :src="'https://www.youtube.com/embed/' + selectedRoutine.youtube_url"
                          frameborder="0"
                          allowfullscreen
                          class="routineVideo"
                        ></iframe>
                      </div>
                      <div class="col-span-4">
                        <p v-if="selectedRoutine.description !== ''" class="font-bold">Description:</p>
                        <p v-if="selectedRoutine.description !== ''" class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">{{ selectedRoutine.description }}</p>
                        <p v-if="selectedRoutine.safety_info !== ''" class="mt-5 text-secondary-500 font-bold">Safety Information:</p>
                        <p v-if="selectedRoutine.safety_info !== ''" class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">{{ selectedRoutine.safety_info }}</p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-1 gap-5"
                    v-for="exercise in selectedRoutine.routineexercise_set"
                    :key="exercise.id"
                  >
                    <div class="bg-white rounded p-5 grid grid-cols-1 lg:grid-cols-4 gap-5 my-2 border-b">
                      <div class="border-b md:border-none">
                        <p class="text-[#0b7db6] font-bold uppercase">{{ exercise.exercise.name }}</p>
                        <img
                          :src="exercise.exercise.animation"
                          class="my-3"
                        />
                      </div>
                      <div class="col-span-2">

                        <p class="font-bold">Description:</p>
                        <p class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">{{ exercise.exercise.description}}</p>

                        <p
                          v-if="exercise.exercise.safety_info !== ''"
                          class="mt-5 text-secondary-500 font-bold"
                        >Safety Information:</p>
                        <p
                          v-if="exercise.exercise.safety_info !== ''"
                          class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full"
                        >{{ exercise.exercise.safety_info }}</p>
                      </div>
                      <div>
                        <p class="font-bold">Stats:</p>
                        <div class="bg-[#F8F8F8] shadow-md text-xs p-5 rounded w-full">
                          <div class="flex justify-between">
                            <div class>
                              <p class="text-gray-500">Reps</p>
                              <span v-if="exercise.reps">{{ exercise.reps }}</span>
                              <span v-else>N/A</span>
                            </div>
                            <div class>
                              <p class="text-gray-500">Time (in Seconds)</p>
                              <span v-if="exercise.time">{{ exercise.time }}</span>
                              <span v-else>N/A</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ADD ROUTINES FROM LIST -->
        <AddModal v-if="addFrom === 'list'">
          <template v-slot:header>Select Routines</template>

          <template v-slot:content>
            <div class="bg-white rounded p-10 grid grid-cols-1 gap-5">
              <div class="grid grid-cols-3 gap-5">
                <div class="col-span-2">
                  <base-select
                    v-model="categories"
                    label="Filter Routines"
                    :items="[{ text: 'Strengthen', value: 'strengthen' }, { text: 'Rehab', value: 'rehab' }]"
                    item-text="text"
                    item-value="value"
                  ></base-select>
                </div>
                <div style="text-align: end;">
                  <button
                      class="primaryBtn self-center"
                      @click="searchCategoriesSubmit()"
                      style="width: 50%;"
                  >
                    Search
                  </button>
                </div>
              </div>
              <div
                v-for="templateItem in templateList"
                :key="templateItem.id"
                :templateItem="templateItem"
              >
                <div class="bg-[#F8F8F8] p-5 rounded shadow-md">
                  <div class="flex w-full pb-3">
                    <div class="headingOne text-xl font-black">{{ templateItem.name }}</div>

                    <div class="bg-secondary-500 text-white rounded-full mx-5 px-2 py-0.5 text-xs self-center">
                      {{
                        templateItem.category
                      }}
                    </div>
                    <div class="flex-auto"></div>

                    <div class="flex items-center">
                      <input
                        type="checkbox"
                        id="templateItem.id"
                        :value="templateItem.id"
                        v-model="routineFormFromList.selectedRoutines"
                        class="opacity-0 absolute h-8 w-8"
                      />
                      <div
                        class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                      >
                        <svg
                          class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                          version="1.1"
                          viewBox="0 0 17 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <g
                              transform="translate(-9 -11)"
                              fill="#ed1f26"
                              fill-rule="nonzero"
                            >
                              <path
                                d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div
                    class="grid grid-cols-3 gap-5"
                    v-if="templateItem.routineexercise_set.length < 3"
                  >
                    <div
                      class="py-2 my-2 bg-white rounded"
                      v-for="exerciseSet in templateItem.routineexercise_set"
                      :key="exerciseSet"
                    >
                      <div class="text-xs text-center text-[#0b7db6] uppercase">
                        {{
                          exerciseSet.exercise.name
                        }}
                      </div>
                      <img
                        :src="exerciseSet.exercise.animation"
                        class="my-3 mx-auto"
                      />
                      <div class="w-1/2 mx-auto grid grid-cols-2 text-xs">
                        <p class="self-center text-gray-500">Reps:</p>
                        <p class="text-center px-2">{{ exerciseSet.reps }}</p>
                      </div>
                    </div>
                  </div>

                  <div
                    v-else
                    class="grid grid-cols-3 gap-5"
                  >
                    <div class="py-2 my-2 bg-white rounded">
                      <div class="text-xs text-center text-[#0b7db6] uppercase">
                        {{
                          templateItem.routineexercise_set[0].exercise.name
                        }}
                      </div>
                      <img
                        :src="templateItem.routineexercise_set[0].exercise.animation"
                        class="my-3 mx-auto"
                      />
                      <div class="w-1/2 mx-auto grid grid-cols-2 text-xs">
                        <p class="self-center text-gray-500">Reps:</p>
                        <p class="text-center px-2">{{ templateItem.routineexercise_set[0].reps }}</p>
                      </div>
                    </div>
                    <div class="py-2 my-2 bg-white rounded">
                      <div class="text-xs text-center text-[#0b7db6] uppercase">
                        {{
                          templateItem.routineexercise_set[1].exercise.name
                        }}
                      </div>
                      <img
                        :src="templateItem.routineexercise_set[1].exercise.animation"
                        class="my-3 mx-auto"
                      />
                      <div class="w-1/2 mx-auto grid grid-cols-2 text-xs">
                        <p class="self-center text-gray-500">Reps:</p>
                        <p class="text-center px-2">{{ templateItem.routineexercise_set[1].reps }}</p>
                      </div>
                    </div>
                    <div class="py-2 my-2 bg-white rounded">
                      <div class="text-xs text-center text-[#0b7db6] uppercase">
                        {{
                          templateItem.routineexercise_set[2].exercise.name
                        }}
                      </div>
                      <img
                        :src="templateItem.routineexercise_set[2].exercise.animation"
                        class="my-3 mx-auto"
                      />
                      <div class="w-1/2 mx-auto grid grid-cols-2 text-xs">
                        <p class="self-center text-gray-500">Reps:</p>
                        <p class="text-center px-2">{{ templateItem.routineexercise_set[2].reps }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <div>
              <button
                class="secondaryBtn text-xs mx-1 border-none px-2"
                :disabled="disablePreviousButton"
                @click="previousPage()"
              >Previous Page</button>
              {{ page }}
              <button
                class="secondaryBtn text-xs mx-1 border-none px-2"
                :disabled="disableNextButton"
                @click="nextPage()"
              >Next Page</button>
            </div>

            <div class="flex justify-around">
              <button
                class="secondaryBtn w-40 mx-5"
                @click="addFrom = ''"
              >Close</button>
              <button
                class="primaryBtn w-40"
                @click="addRoutine()"
              >Add Routines</button>
            </div>
          </template>
          <template v-slot:background>
            <div
              v-if="addFrom === 'list'"
              class="opacity-75 fixed inset-0 z-40 bg-black"
            ></div>
          </template>
        </AddModal>

        <!-- ADD CUSTOM ROUTINES -->
        <AddModal v-if="addFrom === 'custom'">
          <template v-slot:header>Select Exercises</template>

          <template v-slot:content>
            <!-- CHECKBOXES -->
            <div
              v-if="custom === 'lists'"
              class="lists w-3/5 mx-auto justify-items-center grid grid-cols-2 gap-3 mt-5"
            >
              <div>
                <p class="mb-5 font-bold">BODY PART</p>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Chest'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Chest</label>
                </div>

                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Back (C-T-L Spine)'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Back (C-T-L Spine)</label>
                </div>

                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Abdomen'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Abdomen</label>
                </div>

                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Arm'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Arm</label>
                </div>

                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Elbow/Forearm'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Elbow/Forearm</label>
                </div>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Wrist/Hand'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Wrist/Hand</label>
                </div>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Buttock'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Buttock</label>
                </div>

                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Hip'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Hip</label>
                </div>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Upper Leg'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Upper Leg</label>
                </div>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Knee'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Knee</label>
                </div>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Lower Leg'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Lower Leg</label>
                </div>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'Ankle/Foot'"
                    v-model="checkedBodyPart"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Ankle/Foot</label>
                </div>
              </div>

              <div>
                <p class="mb-5 font-bold">EXERCISE TYPE</p>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'strengthen'"
                    v-model="checkedExerciseType"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-primary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-[#035795] pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Strengthen</label>
                </div>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'stretch'"
                    v-model="checkedExerciseType"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-primary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-[#035795] pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Stretch</label>
                </div>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'yoga'"
                    v-model="checkedExerciseType"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-primary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-[#035795] pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Yoga</label>
                </div>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'strap_brace'"
                    v-model="checkedExerciseType"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-primary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-[#035795] pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">Strap/Brace</label>
                </div>


                <div class="flex items-center my-2">
                  <input
                    type="checkbox"
                    :value="'rom'"
                    v-model="checkedExerciseType"
                    class="opacity-0 absolute h-8 w-8"
                  />
                  <div
                    class="bg-white border-2 rounded-md border-primary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                  >
                    <svg
                      class="fill-current hidden w-4 h-4 text-[#035795] pointer-events-none"
                      version="1.1"
                      viewBox="0 0 17 12"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          transform="translate(-9 -11)"
                          fill="#ed1f26"
                          fill-rule="nonzero"
                        >
                          <path
                            d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                          />
                        </g>
                      </g>
                    </svg>
                  </div>
                  <label class="select-none">ROM</label>
                </div>
              </div>
            </div>

            <!-- SELECTED EXERCISE TYPES -->
            <div v-else-if="custom === 'exercises'">
              <p class="mb-5 font-bold">EXERCISES</p>

              <div
                v-for="customExercise in customExerciseList"
                :key="customExercise.id"
                :customExercise="customExercise"
              >
                <div class="bg-[#F8F8F8] rounded p-5 my-5">
                  <div class="flex justify-between items-center">
                    <div class="flex">
                      <p class="text-[#0b7db6] font-bold uppercase self-center">{{ customExercise.name }}</p>
                      <!-- exercise type -->
                      <div
                        class="bg-secondary-500 w-fit text-white rounded-full my-2 mx-3 px-2 py-0.5 text-xs self-center"
                      >{{ customExercise.exercise_type }}</div>
                    </div>
                    <!-- input -->
                    <div class="flex items-center">
                      <input
                        type="checkbox"
                        id="templateItem.id"
                        :value="customExercise.id"
                        v-model="routineFormFromCustom.selectedCustomExercises"
                        class="opacity-0 absolute h-8 w-8"
                      />
                      <div
                        class="bg-white border-2 rounded-md border-secondary-400 w-8 h-8 flex flex-shrink-0 justify-center items-center mr-2"
                      >
                        <svg
                          class="fill-current hidden w-4 h-4 text-secondary-600 pointer-events-none"
                          version="1.1"
                          viewBox="0 0 17 12"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g
                            fill="none"
                            fill-rule="evenodd"
                          >
                            <g
                              transform="translate(-9 -11)"
                              fill="#ed1f26"
                              fill-rule="nonzero"
                            >
                              <path
                                d="m25.576 11.414c0.56558 0.55188 0.56558 1.4439 0 1.9961l-9.404 9.176c-0.28213 0.27529-0.65247 0.41385-1.0228 0.41385-0.37034 0-0.74068-0.13855-1.0228-0.41385l-4.7019-4.588c-0.56584-0.55188-0.56584-1.4442 0-1.9961 0.56558-0.55214 1.4798-0.55214 2.0456 0l3.679 3.5899 8.3812-8.1779c0.56558-0.55214 1.4798-0.55214 2.0456 0z"
                              />
                            </g>
                          </g>
                        </svg>
                      </div>
                    </div>
                  </div>

                  <!-- body parts -->
                  <div class="flex justify-start">
                    <div
                      v-for="body_part in customExercise.body_parts"
                      :key="body_part.name"
                      class="self-center"
                    >
                      <div
                        class="bg-primary-400 w-fit text-white rounded-full my-2 mx-1 px-2 py-0.5 text-xs self-center"
                      >{{ body_part.name }}</div>
                    </div>
                  </div>

                  <div class="bg-white rounded p-5 grid grid-cols-1 lg:grid-cols-3 gap-5 my-2 border-b">
                    <div class="border-b md:border-none pb-5">
                      <img
                        :src="customExercise.animation"
                        class="my-3"
                      />
                    </div>
                    <div class="col-span-2">
                      <p class="font-bold">Description:</p>
                      <p class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">{{ customExercise.description }}</p>

                      <p
                        v-if="customExercise.safety_info !== ''"
                        class="mt-5 text-secondary-500 font-bold"
                      >Safety Information:</p>
                      <p
                        v-if="customExercise.safety_info !== ''"
                        class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full"
                      >{{ customExercise.safety_info }}</p>
                    </div>

                    <div>
                      <p class="font-bold">Stats:</p>
                      <div class="bg-[#F8F8F8] shadow-md text-xs p-5 rounded w-full">
                        <div class="flex justify-between">
                          <div class>
                            <p class="text-gray-500">Reps</p>
                            <span v-if="customExercise.time">{{ customExercise.reps }}</span>
                            <span v-else>N/A</span>
                          </div>
                          <div class>
                            <p class="text-gray-500">Time (in Seconds)</p>
                            <span v-if="customExercise.time">{{ customExercise.time }}</span>
                            <span v-else>N/A</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <button
              class="secondaryBtn w-40 mx-5"
              @click="addFrom = false"
            >Close</button>
            <button
              v-if="custom === 'lists'"
              class="primaryBtn w-40"
              @click="viewCustom()"
            >View Exercises</button>
            <button
              @click="addExercises()"
              v-if="custom === 'exercises'"
              class="primaryBtn w-40"
            >Add Exercises</button>
          </template>
          <template v-slot:background>
            <div
              v-if="addFrom === 'custom'"
              class="opacity-75 fixed inset-0 z-40 bg-black"
            ></div>
          </template>
        </AddModal>

        <!-- START ROUTINE -->
        <AddModal v-if="startModal === true">
          <template v-slot:header>Start Routine</template>
          <template v-slot:content>
            <div class="bg-[#F8F8F8] p-5 rounded shadow-md">
              <div class="flex justify-between w-full pb-3">
                <div class="font-bold self-center">{{ currentRoutine.name }}</div>
              </div>
              <div class="grid grid-cols-1 gap-5" v-if="currentRoutine.description != '' || currentRoutine.safety_info != ''">
                <div class="bg-white rounded p-5 grid grid-cols-1 lg:grid-cols-4 gap-5 my-2 border-b">
                  <div v-if="selectedRoutine.youtube_url !== ''" class="col-span-4" style="text-align: -webkit-center;">
                    <iframe
                      :src="'https://www.youtube.com/embed/' + selectedRoutine.youtube_url"
                      frameborder="0"
                      allowfullscreen
                      class="routineVideo"
                    ></iframe>
                  </div>
                  <div class="col-span-4">
                    <p v-if="currentRoutine.description !== ''" class="font-bold">Description:</p>
                    <p v-if="currentRoutine.description !== ''" class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">{{ currentRoutine.description }}</p>
                    <p v-if="currentRoutine.safety_info !== ''" class="mt-5 text-secondary-500 font-bold">Safety Information:</p>
                    <p v-if="currentRoutine.safety_info !== ''" class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">{{ currentRoutine.safety_info }}</p>
                  </div>
                </div>
              </div>
              <div
                class="grid grid-cols-1 gap-5"
                v-for="(exercise, index) in currentRoutine.routineexercise_set"
                :key="index"
              >
                <div class="bg-white rounded p-5 grid lg:grid-cols-4 gap-5 my-5 border-b">
                  <div class="border-b md:border-none col-span-1">
                    <p class="text-[#0b7db6] font-bold uppercase">{{ exercise.exercise.name }}</p>

                    <img
                      :src="exercise.exercise.animation"
                    />
                  </div>

                  <div class="col-span-2 ml-5">
                    <p class="font-bold">Description</p>
                    <div class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">
                      {{ exercise.exercise.description }}
                    </div>

                    <p
                      v-if="exercise.exercise.safety_info !== ''"
                      class="mt-5 text-secondary-500 font-bold"
                    >Safety Information:</p>

                    <div
                      v-if="exercise.exercise.safety_info !== ''"
                      class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full"
                    >{{ exercise.exercise.safety_info }}</div>
                  </div>

                  <div>
                    <p class="font-bold">Stats</p>
                    <div class="bg-[#F8F8F8] shadow-md text-xs p-5 rounded w-full">
                      <div class="flex justify-between">
                        <div v-if="exercise.reps">
                          <p class="text-gray-500">Reps</p>
                          <span>{{ exercise.reps }}</span>
                        </div>
                        <div>
                          <p class="text-gray-500">Time Tracked</p>
                          {{ millisecondsToTime(exercise.timeTracked) }}
                        </div>
                        <div v-if="exercise.time">
                          <p class="text-gray-500">Time (in Seconds)</p>
                          <span>{{ exercise.time }}</span>
                        </div>
                      </div>
                      <div class="mt-4 grid grid-cols-1 content-center gap-2 text-xs self-center">
                        <button
                          v-if="exercise.exerciseStarted == true"
                          class="secondaryBtn text-xs border-none"
                          :disabled="true"
                        >
                          Start
                          <br />Exercise
                        </button>
                        <button
                          v-else-if="exercise.exerciseStopped == false"
                          class="secondaryBtn text-xs border-none"
                          @click="startExerciseTimer(index)"
                        >
                          Start
                          <br />Exercise
                        </button>
                        <button
                          v-else
                          class="secondaryBtn text-xs border-none"
                          :disabled="true"
                        >
                          Start
                          <br />Exercise
                        </button>
                        <button
                          v-if="exercise.exerciseStopped == false"
                          class="secondaryBtn text-xs border-none mx-1 px-2"
                          @click="skipped = skipped + 1; stopExerciseTimer(index)"
                        >
                          Skip
                          <br />Exercise
                        </button>
                        <button
                          v-else
                          class="secondaryBtn text-xs border-none mx-1 px-2"
                          :disabled="true"
                        >
                          Skip
                          <br />Exercise
                        </button>
                        <button
                          v-if="exercise.exerciseStopped == false"
                          class="secondaryBtn text-xs border-none mx-1 px-2"
                          @click="completed = completed + 1; stopExerciseTimer(index)"
                        >
                          Complete
                          <br />Exercise
                        </button>
                        <button
                          v-else
                          class="secondaryBtn text-xs border-none"
                          :disabled="true"
                        >
                          Complete
                          <br />Exercise
                        </button>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <button
              class="secondaryBtn w-40 mx-5"
              @click="startModal = false; resetRoutineTimer()"
            >Close</button>
            <div class="text-secondary-500 text-xl font-bold">
              <span>{{ millisecondsToTime(routineTimer) }}</span>
            </div>
            <button
              v-if="isExerciseRunning == false && (completed + skipped) == numberOfExercises"
              class="primaryBtn w-40 mx-5"
              @click="createSession(currentRoutine); stopRoutineTimer()"
            >Finish Routine</button>
            <button
              v-else
              class="primaryBtn w-40 mx-5"
              :disabled="true"
            >Finish Routine</button>
          </template>
          <template v-slot:background>
            <div
              v-if="startModal === true"
              class="opacity-75 fixed inset-0 z-40 bg-black"
            ></div>
          </template>
        </AddModal>

        <SummaryModal v-if="startModal === false && finishedRoutine === true">
          <template v-slot:header>Start Routine Summary</template>
          <template v-slot:content>
            <div class="bg-[#F8F8F8] p-5 rounded shadow-md">
              <div class="flex justify-between w-full pb-3">
                <div class="font-bold self-center">{{ currentRoutine.name }}</div>
              </div>
              <div class="grid grid-cols-1 gap-5" v-if="currentRoutine.description != '' || currentRoutine.safety_info != ''">
                <div class="bg-white rounded p-5 grid grid-cols-1 lg:grid-cols-4 gap-5 my-2 border-b">
                  <div v-if="currentRoutine.youtube_url !== ''" class="col-span-4" style="text-align: -webkit-center;">
                    <iframe
                      :src="'https://www.youtube.com/embed/' + currentRoutine.youtube_url"
                      frameborder="0"
                      allowfullscreen
                      class="routineVideo"
                    ></iframe>
                  </div>
                  <div class="col-span-4">
                    <p v-if="currentRoutine.description !== ''" class="font-bold">Description:</p>
                    <p v-if="currentRoutine.description !== ''" class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">{{ currentRoutine.description }}</p>
                    <p v-if="currentRoutine.safety_info !== ''" class="mt-5 text-secondary-500 font-bold">Safety Information:</p>
                    <p v-if="currentRoutine.safety_info !== ''" class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">{{ currentRoutine.safety_info }}</p>
                  </div>
                </div>
              </div>
              <div
                class="grid grid-cols-1 gap-5"
                v-for="exercise in currentRoutine.routineexercise_set"
                :key="exercise.id"
              >
                <div class="bg-white rounded p-5 grid lg:grid-cols-4 gap-5 my-5 border-b">
                  <div class="border-b md:border-none col-span-1">
                    <p class="text-[#0b7db6] font-bold uppercase">{{ exercise.exercise.name }}</p>

                    <img
                      :src="exercise.exercise.animation"
                    />
                  </div>

                  <div class="col-span-2 ml-5">
                    <p class="font-bold">Description</p>
                    <div class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">
                      {{ exercise.exercise.description }}
                    </div>
                    <br/>
                    <p class="font-bold">Stats</p>
                    <div class="bg-[#F8F8F8] shadow-md text-xs p-5 rounded w-full">
                        <div class="flex justify-between">
                          <div v-if="exercise.reps">
                            <p class="text-gray-500">Reps</p>
                            <span>{{ exercise.reps }}</span>
                          </div>
                          <div>
                            <p class="text-gray-500">Time Tracked</p>
                            {{ millisecondsToTime(exercise.timeTracked) }}
                          </div>
                          <div v-if="exercise.time">
                            <p class="text-gray-500">Time (in Seconds)</p>
                            <span>{{ exercise.time }}</span>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 gap-5">
                <div class="bg-white rounded p-5 grid lg:grid-cols-4 gap-5 my-5 border-b">
                  <div class="border-b md:border-none col-span-1">
                    <p class="text-gray-500">Total Time:</p>
                    <span>{{ millisecondsToTime(routineTimer) }}</span>
                  </div>
                  <div class="border-b md:border-none col-span-1">
                    <p class="text-gray-500">Total Exercise Time:</p>
                    <span>{{ millisecondsToTime(exerciseTimeTotal) }}</span>
                  </div>
                  <div class="border-b md:border-none col-span-1">
                    <p class="text-gray-500">Total Exercises Completed:</p>
                    <span>{{ completed }}</span>
                  </div>
                  <div class="border-b md:border-none col-span-1">
                    <p class="text-gray-500">Total Exercises Skipped:</p>
                    <span>{{ skipped }}</span>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <button
              class="secondaryBtn w-40 mx-5"
              @click="startModal = false; finishedRoutine = false; resetRoutineTimer()"
            >Close</button>
          </template>
          <template v-slot:background>
            <div
              v-if="startModal === false && finishedRoutine === true"
              class="opacity-75 fixed inset-0 z-40 bg-black"
            ></div>
          </template>
        </SummaryModal>

        <!-- EDIT ROUTINE -->
        <AddModal v-if="editModal === true">
          <template v-slot:header>Edit Routine</template>
          <template v-slot:content>
            <div class="bg-[#F8F8F8] p-5 rounded shadow-md">
              <div class="flex justify-between w-full pb-3">

                <base-input
                  v-model="editingRoutine.name"
                  label="Routine Name"
                ></base-input>

                <base-input
                  v-model="editingRoutine.youtube_url"
                  label="YouTube URL"
                ></base-input>

                <base-select
                  v-model="editingRoutine.category"
                  label="Category"
                  :items="routineCategories"
                  item-text="text"
                  item-value="value"
                ></base-select>
              </div>
              <div>
                <base-text-area
                  v-model="editingRoutine.description"
                  label="Description"
                  custom-placeholder=""
                ></base-text-area>
                <base-text-area
                  v-model="editingRoutine.safety_info"
                  label="Safety Info"
                  custom-placeholder=""
                ></base-text-area>
              </div>

              <div
                class="grid grid-cols-1 gap-5"
                v-for="exercise in editingRoutine.routineexercise_set"
                :key="exercise.id"
              >
                <div class="bg-white rounded p-5 grid lg:grid-cols-4 gap-5 my-5 border-b">
                  <div class="border-b md:border-none col-span-1">
                    <p class="text-[#0b7db6] font-bold uppercase">{{ exercise.exercise.name }}</p>
                    <img
                      :src="exercise.exercise.animation"
                      class="my-3"
                    />
                  </div>
                  <div class="col-span-2 ml-5">

                    <p class="font-bold">Description:</p>
                    <div class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full">
                      {{ exercise.exercise.description }}
                    </div>

                    <p
                      v-if="exercise.exercise.safety_info !== ''"
                      class="mt-5 text-secondary-500 font-bold"
                    >Safety Information:</p>
                    <div
                      v-if="exercise.exercise.safety_info !== ''"
                      class="bg-[#F8F8F8] shadow-md text-xs p-2 rounded self-center w-full"
                    >{{ exercise.exercise.safety_info }}
                    </div>
                  </div>
                  <div>
                    <p class="font-bold">Stats:</p>
                    <div class="bg-[#F8F8F8] shadow-md text-xs p-5 rounded w-full">
                      <div class="flex justify-between">
                        <div class>
                          <p class="text-gray-500">Reps</p>
                          <span>{{ exercise.reps }}</span>
                        </div>
                        <div class>
                          <p class="text-gray-500">Time (in Seconds)</p>
                          <span>{{ exercise.time }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-end mt-5">
                      <button
                        @click="deleteExercise(editingRoutine.id, exercise.exercise.id)"
                        class="primaryBtn text-xs mx-1 border-none px-2"
                      >
                        Delete Exercise
                        <img
                          class="h-5 w-5 ml-2"
                          src="../assets/img/Trash.svg"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template v-slot:footer>
            <button
              class="secondaryBtn w-40 mx-5"
              @click="editModal = false"
            >Close</button>
            <button
              class="primaryBtn w-40 mx-5"
              @click="updateRoutine()"
            >Update</button>
          </template>
          <template v-slot:background>
            <div
              v-if="editModal === true"
              class="opacity-75 fixed inset-0 z-40 bg-black"
            ></div>
          </template>
        </AddModal>

        <!-- DELETE ROUTINE -->
        <ConfirmationModal v-if="deleteRoutineModal === true">
          <template v-slot:header>Delete Routine</template>
          <template v-slot:content>
            <p>Are you sure you want to delete this routine?</p>
          </template>
          <template v-slot:footer>
            <button
              class="secondaryBtn w-40 mx-5"
              @click="deleteRoutineModal = false"
            >Close</button>
            <button
              class="primaryBtn w-40 mx-5"
              @click="deleteRoutine(selectedRoutineID);"
            >Delete</button>
          </template>
          <template v-slot:background>
            <div
              v-if="deleteRoutineModal === true"
              class="opacity-75 fixed inset-0 z-40 bg-black"
            ></div>
          </template>
        </ConfirmationModal>
      </div>
    </div>
  </section>

  <FooterComponent></FooterComponent>
</template>

<script setup>
  import { ref, onMounted, inject, watch, computed, reactive  } from "vue";
  import BaseCard from "../assets/BaseCard.vue";
  import HeaderShape from "../assets/HeaderShape.vue";
  import FooterComponent from "../components/FooterComponent.vue";
  import { useStore } from "vuex";
  import base from "../services/api";
  import AddModal from "@/assets/AddModal.vue";
  import ConfirmationModal from "@/assets/ConfirmationModal.vue";
  import SummaryModal from "@/assets/SummaryModal.vue";
  import { formatISO } from "date-fns";
  import { toast } from 'vue3-toastify';
  import 'vue3-toastify/dist/index.css';

  import BaseInput from "../../src/assets/Form/BaseInput.vue";
  import BaseTextArea from "../../src/assets/Form/BaseTextArea.vue";
  import BaseSelect from "@/assets/Form/BaseSelect.vue";

  const store = useStore();
  const user = computed(() => store.getters["auth/getUser"]);

  // Get auth token for bearer
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': `ASSIC ${user.value.token}`,
  };

  const API = inject("axios");
  const disablePreviousButton = ref(true);
  const disableNextButton = ref(true);
  const finishedRoutine = ref(false);
  const routineTimer = ref(0);
  const toDate = ref();
  let templateList = ref([]);
  let selectedRoutinesList = ref([])
  let checkedBodyPart = ref([])
  let checkedExerciseType = ref([])
  let customExerciseList = ref([])
  const routine = ref(0);
  const routineName = ref("Routine 1");
  const page = ref(1);
  const maxPages = ref();
  const addFrom = ref();
  const custom = ref();
  const editModal = ref(false);
  const categories = ref();
  const editingRoutine = ref({
    id: "",
    name: "",
    youtube_url: "",
    safety_info: "",
    category: "",
    contributor: 1,
    description: "",
  });

  const routineCategories = ref([{ text: 'Strengthen', value: 'strengthen' }, { text: 'Rehab', value: 'rehab' }]);

  const startModal = ref(false);
  const currentRoutine = ref();
  const skipped = ref(0);
  const completed = ref(0);
  const numberOfExercises = ref(0);
  const exerciseTimeTotal = ref(0);
  const totalTime = ref(routineTimer);
  const deleteRoutineModal = ref(false);
  const time_total = ref();
  const time_exercising = ref();
  const activeInfo = reactive({});

  let routineFormFromList = ref({
    selectedRoutines: [],
  });

  const selectedRoutineID = ref(null);

  const routineFormFromCustom = ref({
    selectedCustomExercises: [],
  });

  const session_routine_exercises = ref([]);

  const deletedExercise = ref({
    exerciseID: [],
  });

  const setupInfoButtonArray = async (routineList) => {
    routineList.value.forEach((routine) => {
      activeInfo[routine.id] = false;
    });
  }

  const toggleInfo = async (i) => {
    activeInfo[i] = activeInfo[i] ? !activeInfo[i] : true;
  };

  const expand = async (id) => {
    var expandID = document.getElementById(id);
    if (expandID.style.display === "none") {
      expandID.style.display = "block";
    } else {
      expandID.style.display = "none";
    }
  };

  const confirmRoutineDeletionModal = async (routine_id) => {
    deleteRoutineModal.value = true;
    selectedRoutineID.value = routine_id;
  }

  const nextPage = async () => {

    page.value = page.value + 1;

    if(page.value > 1){
      disablePreviousButton.value = false;
    }

    if(page.value == maxPages.value){
      disableNextButton.value = true;
    }

    getRoutineTemplates();
  }

  const previousPage = async () => {
    if(page.value > 1){
      page.value = page.value - 1;
    }

    if(page.value < 2){
      disablePreviousButton.value = true;
    }

    getRoutineTemplates();
  }

  const addRoutine = async () => {
    base
      .post("/v2/conditioning/template-routine/copy/", {
        template_routines: routineFormFromList.value.selectedRoutines,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          addFrom.value = false;
          toast('Routine Added Successfully!', {
              type: 'success',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
          getSelectedRoutines();
        }
      })
      .catch((e) => {
        console.error("Add Routines Error", e);
        toast('Unable to Add Routine!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });

    addFrom.value = "";
  };

  const addExercises = async () => {
    base
      .post("/v2/conditioning/routine/create/", {
        exercises: routineFormFromCustom.value.selectedCustomExercises,
        routine: routine.value++,
        name: routineName.value,
      })
      .then((response) => {
        if (response.data?.status === "success") {
          addFrom.value = false;
          toast('Exercise Added Successfully!', {
              type: 'success',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
          getSelectedRoutines();
        }
      })
      .catch((e) => {
        console.error("Add Exercises Error", e);
        toast('Unable to Add Exercise!', {
            type: 'success',
            position: toast.POSITION.BOTTOM_RIGHT,
        })
      });
    getRoutineTemplates();
    addFrom.value = "";
  };

  const searchCategoriesSubmit = async () => {
    page.value = 1;
    searchCategories();
  };

  const searchCategories = async () => {
    // Confirmation needs to be made if all is something we want
    if (categories.value == "all") {
      categories.value = "";
      getRoutineTemplates();
    } else {
      var data = new FormData();
      data.append("page", page.value);
      data.append("category", categories.value);
      base
        .post("/v2/conditioning/template-routine/get/", data)
        .then((response) => {
          if (response.data?.status === "success") {
            maxPages.value = response.data.data.pages;
            templateList.value = response.data.data.result;

            if(page.value == maxPages.value){
              disableNextButton.value = true;
            } else {
              disableNextButton.value = false;
            }

          }
        })
        .catch((e) => {
          console.error("View profile Error", e);
          toast('Unable to Search for Categories!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    }
  };

  const startRoutine = async (curRoutine) => {
    startModal.value = true;

    numberOfExercises.value = curRoutine.routineexercise_set.length

    curRoutine.routineexercise_set.forEach((exercise) => {
      exercise.timeTracked = 0;
      exercise.exerciseStopped = false;
      exercise.exerciseStarted = false;
    });
    currentRoutine.value = curRoutine;
  };

  const selectedExercise = ref(null);
  const selectedRoutine = ref(null);
  const selectedExerciseChanged = ref(false);
  const selectedRoutineChanged = ref(false);
  const isRoutineRunning = ref(false);
  const isExerciseRunning = ref(false);

  const startRoutineTimer = async (index) => {
    isRoutineRunning.value = true
    selectedRoutine.value = index;
    await routineTimerCount();
  };

  const startExerciseTimer = async (index) => {
    isExerciseRunning.value = true
    selectedExercise.value = index;
    await exerciseTimerCount();
  };

  watch(selectedExercise, () => {
    if (selectedExercise.value == null) {
      selectedExerciseChanged.value = true;
    }

    if (selectedRoutine.value == null) {
      selectedRoutineChanged.value = true;
    }
  });

  const routineTimerCount = async () => {
    if (selectedRoutine.value == null || selectedRoutineChanged.value) {
      // stop the current active timer
      selectedRoutineChanged.value = false;
      return;
    }

    routineTimer.value += 1000;
    setTimeout(() => {
      routineTimerCount();
    }, 1000);
  };

  const exerciseTimerCount = async () => {
    if (selectedExercise.value == null || selectedExerciseChanged.value) {
      // stop the current active timer
      selectedExerciseChanged.value = false;
      return;
    }

    currentRoutine.value.routineexercise_set[selectedExercise.value].exerciseStopped = false;
    currentRoutine.value.routineexercise_set[selectedExercise.value].exerciseStarted = true;

    currentRoutine.value.routineexercise_set[selectedExercise.value].timeTracked += 1000;
    setTimeout(() => {
      exerciseTimerCount();
    }, 1000);
  };

  const stopRoutineTimer = async () => {
    selectedRoutine.value = null;
  };

  const resetRoutineTimer = async () => {
    stopRoutineTimer();
    routineTimer.value = 0;
  };

  const processSessionExercise = async (curRoutine) => {
    curRoutine.routineexercise_set.forEach((exercise) => {

      session_routine_exercises.value.push({
        actual_reps: exercise.reps,
        actual_time: parseFloat(formatTime(exercise.timeTracked)),
        routine_exercise_id: exercise.id,
        });
    });
  }

  const stopExerciseTimer = async (index) => {
    selectedExercise.value = index;

    isExerciseRunning.value = false;
    currentRoutine.value.routineexercise_set[selectedExercise.value].exerciseStopped = true;
    currentRoutine.value.routineexercise_set[selectedExercise.value].exerciseStarted = false;
    exerciseTimeTotal.value += currentRoutine.value.routineexercise_set[selectedExercise.value].timeTracked;

    selectedExercise.value = null;
  };

  const millisecondsToTime = (miliseconds) => {
    var seconds = Math.floor(miliseconds / 1000);
    var hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    var minutes = Math.floor(seconds / 60);
    seconds %= 60;

    var formattedTime =
      (hours < 10 ? '0' : '') + hours + ':' +
      (minutes < 10 ? '0' : '') + minutes + ':' +
      (seconds < 10 ? '0' : '') + seconds;

    return formattedTime;
  };

  const formatTime = (miliseconds) => {

    var seconds = Math.floor(miliseconds / 1000);
    var hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    var minutes = Math.floor(seconds / 60);
    seconds %= 60;

    var formattedTime = hours + '.' + minutes;

    return formattedTime;
  };

  const createSession = async (curRoutine) => {

    processSessionExercise(curRoutine);

    const currentDate = new Date();
    toDate.value = formatISO(currentDate);

    time_total.value = parseFloat(formatTime(totalTime.value));
    time_exercising.value = parseFloat(formatTime(exerciseTimeTotal.value));

    finishedRoutine.value = true;
    startModal.value = false;

    const data = {
      routine_id: curRoutine.id,
      created_on: toDate.value,
      time_total: time_total.value,
      time_exercising: time_exercising.value,
      exercises_completed: completed.value,
      exercises_skipped: skipped.value,
      session_routine_exercises: session_routine_exercises.value,
    };

    API.post("/v2/conditioning/routine/session/create/", {
      ...data
    }, { headers })
      .then((response) => {
        if (response.data?.status === "success") {
          finishedRoutine.value = true;
          toast('Routine Session Created Successfully!', {
              type: 'success',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
          getSelectedRoutines();
        } else {
          toast(response.data.message, {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.error("Update Routine Error", e);
        toast('Unable To Create Routine Session!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const editRoutine = async (routine) => {
    editModal.value = true;
    editingRoutine.value = routine;
  };

  const updateRoutine = async () => {
    if(editingRoutine.value.youtube_url == null){
      editingRoutine.value.youtube_url = '';
    }

    base.post("/v2/conditioning/routine/edit/", {
      id: editingRoutine.value.id,
      name: editingRoutine.value.name,
      youtube_url: editingRoutine.value.youtube_url,
      safety_info: editingRoutine.value.safety_info,
      category: editingRoutine.value.category,
      description: editingRoutine.value.description,
    })
      .then((response) => {
        if (response.data.status === "success") {
          editModal.value = false;
          toast('Routine Updated Successfully!', {
              type: 'success',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
          getSelectedRoutines();
        } else {
          toast(response.data.message, {
            type: 'warning',
            position: toast.POSITION.BOTTOM_RIGHT,
          });
        }
      })
      .catch((e) => {
        console.error("Update Routine Error", e);
        toast('Unable To Update Routine!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

    const deleteRoutine = async (id) => {
      base.delete(`/v2/conditioning/routine/delete/?id=${id}`, { headers })
      .then((response) => {
          if (response.data?.status === "success") {
            deleteRoutineModal.value = false;

            toast('Routine Successfully Deleted!', {
                type: 'success',
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            getSelectedRoutines();
          }
        })
        .catch((e) => {
          console.error("Delete Routine Error", e);
          toast('Unable to Delete Routine!', {
              type: 'error',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
        });
    };

  const deleteExercise = async (routineId, exerciseId) => {
    deletedExercise.value.exerciseID = exerciseId;
    base
      .post(`/v2/conditioning/routine/exercise/delete/`, {
        exercise: deletedExercise.value.exerciseID,
        routine: routineId,
      }, { headers })
      .then((response) => {
        if (response.data?.status === "success") {
          toast('Exercise Successfully Deleted!', {
              type: 'success',
              position: toast.POSITION.BOTTOM_RIGHT,
          });
          editModal.value = false;
          getSelectedRoutines();
        }
      })
      .catch((e) => {
        console.error("Delete Routine Error", e);
        toast('Unable to Delete Exercise!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  const viewCustom = async () => {
    base.post("/v2/conditioning/exercises/ ", {
      body_parts: checkedBodyPart.value,
      exercise_types: checkedExerciseType.value,
    }).then((response) => {
      if (response.data?.status === "success") {
        customExerciseList.value = response.data.data;
        custom.value = "exercises";
      }
    }).catch((e) => {
      console.error("View profile Error", e);
      toast('Unable to View Exercises!', {
          type: 'error',
          position: toast.POSITION.BOTTOM_RIGHT,
      });
    });
  };

  const getRoutineTemplates = async () => {
    if (categories.value) {
      searchCategories();
    } else {
      base.post("/v2/conditioning/template-routine/get/", {
        page: page,
      }).then((response) => {
        if (response.data?.status === "success") {
          templateList.value = response.data.data.result;
          if(page.value == maxPages.value){
            disableNextButton.value = true;
          }
        }
      }).catch((e) => {
        console.error("View profile Error", e);
        toast('Unable to View Routine!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
    }
  };

  const getSelectedRoutines = async () => {
    base
      .get("/v2/conditioning/routine/get/")
      .then((response) => {
        if (response.data?.status === "success") {
          selectedRoutinesList.value = response.data.data;
          setupInfoButtonArray(selectedRoutinesList);
        }
      })
      .catch((e) => {
        console.error("View profile Error", e);
        toast('Unable to View Routine!', {
            type: 'error',
            position: toast.POSITION.BOTTOM_RIGHT,
        });
      });
  };

  onMounted(() => {
    getRoutineTemplates();
    getSelectedRoutines();
    resetRoutineTimer();
  });
</script>

<style lang="postcss" scoped>
.lists input {
  @apply h-6 w-6 mr-2;
}

input:checked+div {
  @apply border-secondary-500;
}

input:checked+div svg {
  @apply block;
}

.routineVideo {
  position: relative;
  height: 25rem;
  width: 44.5rem;
  -moz-border-radius: 15px;
  border-radius: 15px;
  overflow: hidden;
}
</style>
